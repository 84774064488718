import React from "react";
import "../assets/css/subscription.css";
import StepPopup from "./step";

function Subscription() {
  return (
    <div className="margin_auto">
    <div className="subcription container">
      <h1>Subscribe to our newsletter</h1>
      <p>Join our community to learn about exclusive deals and latest news</p>
      <form action="https://spheraworld.us8.list-manage.com/subscribe/post?u=00477a26a8ab091e787afccf8&amp;id=f11f811364&amp;f_id=005f70e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
   
      <div className="flex_input">
        <input name="EMAIL" class="required email" id="mce-EMAIL"  type="text" placeholder="Enter Your Email Address" className="inp_sub" />
        <button type="submit" name="subscribe" id="mc-embedded-subscribe">Subscribe</button>

      </div>
      {/* <StepPopup />   */}
   </form>   
      </div>
      
    </div>
  );
}

export default Subscription;
