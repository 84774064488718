import React, { useState } from "react";
import icon2 from "../assets/images/Vector.png";
import icon3 from "../assets/images/Frame-1.png";
import info from "../assets/images/info.png";
import middleImg from "../assets/images/Title1.png";
import middleImg2 from "../assets/images/ChampionsLeague2.png";
import { Col, Row } from "react-bootstrap";
import Mobile_Replace from "./mobile_replace";
import StepPopup from "./step";
import img_1 from '../assets/images/img-1.png';
import img_2 from '../assets/images/img-2.png';
import img_3 from '../assets/images/img-3.png';
import HeadSImg from '../assets/images/LogoHeadStarterlogo_name.png';
import "../assets/css/section.css";



function Sections() {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleClose = () => {
    console.log('Closing the popup');
  };

  return (
    <div className="main_sec2 ">
      <div className="middleimages">
        <div className="banner_img3">
          <img src={middleImg} alt="" className="imgag4" />
        </div>
      </div>
      <div className="container-fluid">
        <Row className="box_parent">
          <div className="col-md-2 ticket_parent">
            <img src={middleImg2} alt="" className="ticket_img" />
          </div>
          <div className="col-md-8">
            <div className="mainsec_3">
              <h3>SPHERA LAUNCH PRIZE DRAW</h3>
              <p className="mainsec_3_p">
                PARTICIPATE IN OUR LAUNCH EVENT TO RECEIVE AN ENTRY INTO OUR
                PRIZE DRAW FOR TICKETS TO THE CHAMPIONS LEAGUE AND M ORE!
              </p>
            </div>
          </div>
          <div className="col-md-2 ticket_parent">
            <img src={middleImg2} alt="" className="ticket2" />
          </div>
        </Row>
      </div>

      <button
        className="learn_button"
        onMouseEnter={handleMouseEnter1}
        onMouseLeave={handleMouseLeave1}
      >
        {isHovered1 ? 'Coming Soon' : 'JOIN OUR INO TO BE ENTERED'}
      </button>

      <div className="three_shirt container">
        <div>
          <div className="img_flex">
            <img src={img_1} alt="" className="img-fluid img-1" />
            <img src={img_2} alt="" className="img-fluid img-2" />
            <img src={img_3} alt="" className="img-fluid img-3" />
          </div>
          <div className="midle_heads">
            <img src={HeadSImg} alt="" />
          </div>
        </div>
        <div className="bag_sec">
          <h3 className="bag_sec_h3">WIN SIGNED EXCLUSIVE FOOTBALL JERSEYS</h3>
          <p className="bag_sec_p">
            JOIN OUR PLATFORM LAUNCH WITH HEADSTARTER TO BE AUTOMATICALLY
            ENTERED TO WIN SIGNED RONALDO, BENZEMA & NEYMAR JR FOOTBALL JERSEYS!
          </p>
        </div>
      </div>
      <div className="newpara">
        <p className="newpara_p">
          Passionate about the beautiful game?
          <br /> Welcome to Sphera World, Join the digital revolution, Connect
          with your favourite clubs, keep track of your favourite leagues and
          own unique collectibles, Whilst being part of an exciting community of
          sports fans. Connect With The Future of Sports Entertainment Today.
        </p>
      </div>
      <div className="container">
        <div className="to_flex">
          <div className="threesec"></div>
          <div className="threesec1" id="shadow1">
            <h1 className="threesec1_h1">SpheraHeads</h1>
            <h3 className="threesec1_h3">Your Digital Adventure Begins.</h3>
            {/* <StepPopup /> */}
            {/* <Popup /> */}
            <div className="box_flex">
              <div>
                {" "}
                <img src={icon3} alt=""/>
                <p className="box_flex_p">Closed community access on Discord, SpheraHeads are your proof of membership.</p>
              </div>

              <div>
                {" "}
                <img src={icon3} alt=""/>
                <p className="box_flex_p">
                  5,000 Fully Unique Individual Identities, Unlocking the Sphera
                  World.
                </p>
              </div>

              <div>
                {" "}
                <img src={icon2} alt=""/>
                <p className="box_flex_p">
                  Raffle entries for rewards, prizes, Memorabilia auctions and
                  giveaways. Open SpheraMoments Packs for free.
                </p>
              </div>
            </div>
            <div className="new_flex">
              <img src={info} alt="" />
              <a href="#" >Learn more</a>
            </div>
          </div>
        </div>
        <div className="step_flex">
          <div className="auto_margin">
            <div className="steps">
              <span>1.</span>
              <span className="line_span"></span>
              <span>Get Your SpheraHead</span>
            </div>
            <p className="step_p">
              Think of your SpheraHead as your passport to the digital realm of
              collectibles. It's your digital identity, your ticket to the game.
            </p>
          </div>

          <div className="auto_margin">
            <div className="steps">
              <span>2.</span>
              <span className="line_span"></span>
              <span>Explore the Marketplace</span>
            </div>
            <p className="step_p">
              Dive into our marketplace, where you'll discover an array of
              digital sports treasures. From trading cards to iconic moments,
              it's all here.
            </p>
          </div>
        </div>

        <div className="step_flex step_flex2">
          <div className="auto_margin">
            <div className="steps">
              <span>3.</span>
              <span className="line_span"></span>
              <span>Collect and Trade</span>
            </div>
            <p className="step_p">
              Buy, sell, and trade your favorite collectibles. It's like having
              a piece of sports history in your pocket.
            </p>
          </div>

          <div className="auto_margin">
            <div className="steps">
              <span>4.</span>
              <span className="line_span"></span>
              <span>Connect with Others</span>
            </div>
            <p className="step_p">
              Join a vibrant community of sports enthusiasts who share your
              passion. Discuss, trade, and celebrate sports together.
            </p>
          </div>
        </div>

        <Mobile_Replace />

        {/* <div className="section3 container">
          <div>
            <img src={vector} alt="" />
          </div>
          <div>
            <h3>Sphera Moments™</h3>
            <p>
              Step into the future of collectibles with Sphera Moments.
              <br /> Don't miss out on owning iconic sports highlights,
              <br /> legendary plays, and more.
            </p>
            <div className="btn_flex">
              <button
                onMouseEnter={handleMouseEnter2}
                onMouseLeave={handleMouseLeave2}
              >
                {isHovered2 ? 'Coming Soon' : 'Coming Soon'}
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Sections;
