import React, { useState, useLayoutEffect, Fragment, useEffect } from 'react'
import Logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom';
import circleLogo from '../../assets/images/circle.png';
import Playerimg from '../../assets/images/bottom-player.png';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { useForgetPasswordOTPMutation } from "../../Redux/Auth/Auth";
import '../../assets/css/conect-web3.css';
import '../../views/ViewStyles.css';
import Toast_Card, { showToast } from "../../components/common/Toast_Card.jsx"
import { BsArrowRight } from "react-icons/bs";
import RingLoader from "react-spinners/RingLoader";
import Btn from '../../components/shared/Btn.jsx'
import { Typography } from '@mui/material';


const ExistingUserOTP = () => {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(1000);


  const [onboard, setonboard] = useState();
  useLayoutEffect(() => {
    setonboard({
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
      color: '#ffffff',
      backgroundImage: ` url(${require('../../assets/images/OnBard/PNG/line5.png')})`,
      backgroundPosition: '250px 63px, 34% -12px',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundColor: 'black',
    })
  }, [])

  // Query 
  const [SendOTP] = useForgetPasswordOTPMutation();
  const handleOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const emailFromSession = sessionStorage.getItem("resetEmail");
    if (!emailFromSession) {
      setIsLoading(false);
      return showToast('OTP is Wrong', 'error');
    } 
    const UserData = { email: emailFromSession, otp: otp };

    const res = await SendOTP(UserData);
    if (res.error) {
      setIsLoading(false);
      return showToast('Please enter correct OTP', 'error')
    }
    setIsLoading(false);
    navigate('/changePassword')
  };

  return (
    <Fragment>
      <Toast_Card />
      <div className='onboard' style={onboard}>
        <div className='onboard_header'>
          <Link to='/'>
            <img src={Logo} alt="" />
          </Link>
          <Link className='arabic_link'>العربية</Link>
        </div>
        <div className='onboardcontainer'>
          <div className='left_column left_side'>
            <h1>Alright! <br /> <span className='colortext'>  Account verification. </span></h1>
            <p className='desc'>A code has been sent to your email address.</p>
          </div>
          <div className='right_column right_col_signup' style={{ overflow: 'visible' }}>
            <div className=' box_detail'>
              <img src={circleLogo} alt="" className='circle_img circle_img_signup' />
              <div className='signup_form verify_form'>
                <h3>Verify</h3>
                <span className='verify_txt'>Enter the code you have received to your email address.</span>
                <form onSubmit={handleOTP}>
                  <Typography variant="body1" color="#fff" sx={{ fontSize: '16px', textAlign: 'center', opacity: '0.5', marginTop: '10px' }}>
                    Remaining Time: {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                  </Typography>
                  <div style={{ margin: "30px 0" }}>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} style={{ width: "30px", margin: "0 5px", textAlign: 'center' }} required />}
                    />
                  </div>
                  <p className='para_link'>Didn’t receive the email? Please check your spam folder or try resending the email in 20 seconds. .</p>
                  <Btn text="Verify" disabled={isLoading} icon={
                    !isLoading ? <BsArrowRight style={{ fontSize: '16px', color: '#FFF' }} /> : <RingLoader color={"#FFFFFF"} loading={true} size={24} />}
                    type="submit"
                    style={{ backgroundColor: '#007BFF', color: '#fff', fontSize: '14px', height: '40px', margin: 'auto', display: 'block' }} />
                </form>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <p className='para_link'> <Link to="/" style={{ textDecoration: "underline" }}>Sign Up</Link> </p>
                  <p className='para_link'> <Link to="/">Resent OTP</Link> </p>
                </div>
              </div>
              <img src={Playerimg} alt="" className='player_img signup_screen_img' />
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  )
}

export default ExistingUserOTP
