import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../../assets/css/header.css";
import Logo from "../../assets/images/logo.png";
import '../../assets/css/profile.css';
import Profile from '../../assets/images/profile/profile-header.png';
import { Typography, Box, Modal, Button } from "@mui/material";
import { IoIosWarning } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 2, pt: 4, pb: 2, borderRadius: '10px'
};


function ProfileHeader() {
  const [isHovered, setIsHovered] = useState(Array(9).fill(false));
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setIsHovered((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const handleMouseLeave = (index) => {
    setIsHovered((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  const handleLogout = () => {
    navigate("/login")
  }

  return (
    <Navbar expand="lg" className="header_profile">
      <Container fluid className="header_flex">
        <Navbar.Brand href="#">
          <img src={Logo} alt="Logo" />

        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav profile_main">

          <Nav className="me-auto nav_profile_flex">
            <span ><input type="text" className="search_bar" placeholder="Search NFT Collections and Collectors" /></span>
            <Nav.Link href="https://Linktr.ee/spherasocial" target="_blank">Marketplace</Nav.Link>
            <Nav.Link href="https://linktr.ee/spheraworld" target="_blank">Sphera Token</Nav.Link>
            <Nav.Link href="https://t.me/spheraworld" target="_blank">INO</Nav.Link>
            <Nav.Link href="https://t.me/spheraworld" target="_blank">FAQ</Nav.Link>
            <Nav.Link href="https://t.me/spheraworld" target="_blank">Community</Nav.Link>
            <Nav.Link href="https://t.me/spheradoc" target="_blank">Docs</Nav.Link>
            <Nav.Link href="https://t.me/spheraworld" target="_blank"><img src={Profile} alt="" className="img_pro" /></Nav.Link>
            <Button onClick={handleOpen} sx={{ backgroundColor: '#FF7F2A', padding: "5px 20px", border: 'none', outline: 'none', borderRadius: '8px', color: "white", "&:hover": { backgroundColor: '#FF7F2A' } }}>Logout</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" sx={{ display: 'flex', alignItem: 'center' }}> <IoIosWarning size={25} color="#FF7F2A" />Are you sure you want to logout? </Typography>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', gap: '20px', marginTop: '20px' }}>
            <Button onClick={handleLogout} style={{ backgroundColor: '#FF7F2A', padding: "5px 20px", border: 'none', outline: 'none', borderRadius: '8px', color: "white", "&:hover": { backgroundColor: '#FF7F2A' } }}> Sure</Button>
            <Button onClick={handleClose} style={{ backgroundColor: '#dc3545', padding: "5px 20px", border: 'none', outline: 'none', borderRadius: '8px', color: "white", "&:hover": { backgroundColor: '#dc3545' } }}>Cancel</Button>
          </Box>
        </Box>
      </Modal>
    </Navbar>
  );
}

export default ProfileHeader;
