import React from 'react'
import './Styles.css'

const Btn = (props) => {
    const { text, style,  icon, onClick, type = "button", disabled } = props;

    return (
        <div>
            <button type={type} onClick={onClick} disabled={disabled} className={`btn ${style}`} >
                {text}
                {icon}
            </button>
        </div>
    )
}

export default Btn
