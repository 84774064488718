import React, { useState, useLayoutEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import circleLogo from "../../assets/images/circle.png";
import Playerimg from "../../assets/images/bottom-player.png";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { useLoginMutation } from "../../Redux/Auth/Auth";
import "../../assets/css/conect-web3.css";
import "../../views/ViewStyles.css";
import Toast_Card, { showToast } from "../../components/common/Toast_Card.jsx"
import { BsArrowRight } from "react-icons/bs";
import RingLoader from "react-spinners/RingLoader";
import Btn from '../../components/shared/Btn.jsx'



const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const navigate = useNavigate();
    const handleSignUp = () => navigate("/onBoard")

    const [onboard, setonboard] = useState();
    useLayoutEffect(() => {
        sessionStorage.clear();
        setonboard({
            width: "100%",
            height: "100vh",
            overflow: "hidden",
            color: "#ffffff",
            backgroundImage: ` url(${require("../../assets/images/OnBard/PNG/line5.png")})`,
            backgroundPosition: "250px 63px, 34% -12px",
            backgroundRepeat: "no-repeat, no-repeat",
            backgroundColor: "black",
        });
    }, []);


    // Query 
    const [UserLogin] = useLoginMutation();
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const UserData = { email: formData.email, password: formData.password };
        
        try {
            const res = await UserLogin(UserData);
            if (res.error) {
                setIsLoading(false);
                showToast('Wrong Credentials.', 'error');
            } else {
                const user = res.data?.user;

                if (user.ExistingUser === 1) {
                    sessionStorage.setItem("resetEmail", user.email);
                    navigate("/VerifyOTP");
                } else if (user.ExistingUser === 0) {
                    if (user.verified === 0) {
                        showToast('Crediantial are wrong.', 'error');
                    }
                    else if (user.verified === 1) {
                        sessionStorage.setItem("Auth_User_id", user.id);
                        sessionStorage.setItem("Auth_User_Email", user.email);
                        if (user.walletID == 0) {
                            console.log(res.data);
                            navigate("/connect");
                        } else {
                            sessionStorage.setItem("userData", user.email);
                            navigate("/profile");
                        }
                    }
                }
            }
        } catch (error) {
            console.error("An error occurred during login:", error);
            showToast('An error occurred. Please try again later.', 'error');
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <Fragment>
            <Toast_Card />
            <div style={onboard} className="onboard">
                <div className="onboard_header">
                    <Link to="/">
                        <img src={Logo} alt="" />
                    </Link>
                    <Link className="arabic_link">العربية</Link>
                </div>
                <div className="onboardcontainer">
                    <div className="left_column left_side">
                    </div>
                    <div className="right_column right_col_signup" style={{ overflow: "visible" }}  >
                        <div className=" box_detail">
                            <img
                                src={circleLogo}
                                alt=""
                                className="circle_img circle_img_signup"
                            />
                            <div className="signup_form">
                                <h3>LogIn </h3>
                                <span className="email_para">
                                    Email <Link>WhatsApp number</Link>
                                </span>
                                <br />
                                <form onSubmit={handleLogin}>

                                    <div>
                                        <label htmlFor="Name">Email</label>
                                        <input type="text" name="email" id="email" value={formData.email} onChange={handleInputChange} required />
                                    </div>
                                    <div className="para_link">
                                        <label htmlFor="Name">Password</label>
                                        <input type="password" name="password" id="password" value={formData.password} onChange={handleInputChange} required />
                                        <Link to={"/forgetPassword"} style={{ width: "100%", display: 'flex', justifyContent: 'end', color: "#FF7F2A" }}>Forget Password</Link>{" "}
                                    </div>
                                    <p className="para_link">By clicking the button, you agree to the{" "}<Link>Terms of Service</Link> .</p>
                                    <Btn text="LogIn" disabled={isLoading} icon={
                                        !isLoading ? <BsArrowRight style={{ fontSize: '16px', color: '#FFF' }} /> : <RingLoader color={"#FFFFFF"} loading={true} size={24} />}
                                        type="submit"
                                        style={{ backgroundColor: '#007BFF', color: '#fff', fontSize: '14px', height: '40px', margin: 'auto', display: 'block' }} />
                                </form>
                                <p className="para_link">Create new account ? <Link to={"/onBoard"}>Sign Up</Link></p>
                            </div>
                            <img
                                src={Playerimg}
                                alt=""
                                className="player_img signup_screen_img"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Login;
