import React, { useState } from "react";
import trofii from "../assets/images/trofii.png";
import Logo2 from "../assets/images/spera-world.png";
import Foot1 from "../assets/images/koora 1.png";
import Foot2 from "../assets/images/logowhite 1.png";
import { FaTwitter, FaTelegramPlane, FaDiscord } from "react-icons/fa";
import "../assets/css/banner.css";


function Banner() {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => { setIsHovered(true) };
  const handleMouseLeave = () => { setIsHovered(false) };


  return (
    <div className="container-fluid">
      <div className="banner_txt">
      <img className=" display_top" src={Logo2} alt=""/>
        <h1>Sphera World</h1>
        <p>Collectibles, Redefined for the Digital Era</p>
        <div className="">
          <div className="flex_area">
            <div>
              <div className="border_color">
                <div className="paratext_trofi">
                <span>Win Prizes by entering our prize draw!</span>
                <p>
                Win  a selection of prizes, join our launch campaign to be entered!
                </p>
                </div>
               
                <img src={trofii} alt="" className="trofi" />
              </div>
              <div className="join_com" >
                <h3 style={{ marginTop: "10px" }}>Join our Community:</h3>
                <div className="dflex_icons">
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <a href="https://twitter.com/spheraworld" target="_blank" rel="noreferrer" className="social_icon" ><FaTwitter size={28} color={"#fff"} /> </a>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <a href="https://discord.com/invite/CwM2H5GUcR" target="_blank" rel="noreferrer"  className="social_icon" style={{ backgroundColor:'#5765EC' }}><FaDiscord size={28} color={"#fff"} /></a>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <a href="https://t.me/SpheraWorldPortal" target="_blank" rel="noreferrer"  className="social_icon"><FaTelegramPlane size={28} color={"#fff"} /></a>
                </div>
              </div>
            </div>
            <div className="right_text">
              <img className="sphera_logo" src={Logo2} alt="" />
              <h3>Get Early Access To The Sphera Platform</h3>
              <p>
                Sphera World revolutionizes the way you collect, connecting you
                with the world of sports like never before. Get early access to
                the sphera platform with the free Sphera Head access NFT .
              </p>
              <div>
              <button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {isHovered ? 'Coming Soon' : 'Learn More'}
              </button> 
              </div>      
              <div className="footer_logo">
                <div>
                  <h6>Powered by</h6>
                  <img src={Foot1} alt="" />
                </div>
                <div>
                  <h6>Enabled by</h6>
                  <img src={Foot2} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="banner_flex_reverse">
          <div className="flex_area">
            <div className="right_text">
              <img className="sphera_logo" src={Logo2} alt="" />
              <h3>Get Early Access To The Sphera Platform</h3>
              <p>
                Sphera World revolutionizes the way you collect, connecting you
                with the world of sports like never before. Get early access to
                the sphera platform with the free Sphera Head access NFT .
              </p>
              <button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                {isHovered ? 'Coming Soon' : 'Learn More'}
              </button>
              <br />
              <div className="footer_logo">
                <div>
                  <h6>Powered by</h6>
                  <img src={Foot1} alt="" />
                </div>
                <div>
                  <h6>Enabled by</h6>
                  <img src={Foot2} alt="" />
                </div>
              </div>
            </div>
            <div>
              <div className="border_color">
                <span>Up to $40,000 worth of Prizes!*</span>
                <p>Win up to $40,000 worth of prizes, join our launch campaign to be entered!</p>
                <img src={trofii} alt="" className="trofi" />
              </div>
              <div className="join_com" >
                <h3 style={{ marginTop: "10px" }}>Join our Community:</h3>
                <div className="dflex_icons">
                  <a href="https://twitter.com/spheraworld" target="_blank" rel="noreferrer" style={{ width: '50px', height: '50px', backgroundColor: '#1cb7eb', borderRadius: '30px', paddingTop: '10px', textAlign: 'center', marginRight: '10px', }}><FaTwitter size={28} color={"#fff"} /> </a>
                  <a href="https://discord.com/invite/CwM2H5GUcR" target="_blank" rel="noreferrer" style={{ width: '50px', height: '50px', backgroundColor: '#5765EC', borderRadius: '30px', paddingTop: '10px', textAlign: 'center', marginRight: '10px' }}><FaDiscord size={28} color={"#fff"} /></a>
                  <a href="https://t.me/SpheraWorldPortal" target="_blank" rel="noreferrer" style={{ width: '50px', height: '50px', backgroundColor: '#1cb7eb', borderRadius: '30px', paddingTop: '10px', textAlign: 'center' }}><FaTelegramPlane size={28} color={"#fff"} /></a>
                </div>
              </div>
            </div>

          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Banner;
