import React from "react";
import "../../assets/css/section2.css";
import Laptop from "../../assets/images/flip-laptop.png";
import Line2 from "../../assets/images/pngline.png";
import "../../assets/css/cardsec.css";
import info from "../../assets/images/info.png";
import icon2 from "../../assets/images/icon7.png";
import icon4 from "../../assets/images/icon9.png";
import CardSlider3 from "./partners";
import vector from "../../assets/images/subject.png";
import Head from "../../assets/images/heads.png";
import CardSectionAr from "./join-us";
import "../../AR/ar.css/ar_section.css";
import JoinWaitlistar from "./Join-waIt-list_ar";

function FlexSections() {
  return (
    <div className="relative_p">
      <div className="sec_4 ">
        <div className="container">
          <div className="row sec4_flex ">
            <div className="col-md-6 laptop_sec">
              <img src={Laptop} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 sec4_text sec4_text_ar ">
              <h3>سوق عالم سفيرا </h3>
              <h5>جمع. تواصل. ازدهر.</h5>
              <div className="ul_sec ul_sec_ar">
                <p>
                  استكشف واشتري المقتنيات الرقمية والمذكرات والتجارب الحصرية من
                  الرياضيين وكيانات الرياضة:
                </p>
                <div className="ul_sec_ar_div">
                  <li>مركز نظام سفيرا </li>
                  <li>منصة تداول NFT</li>
                  <li>مدعوم بعملات Sphera $</li>
                </div>

                <div className="btn_flex btn_flex_ar">
                  <JoinWaitlistar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={Line2} alt="" className="line2" />

      <div className=" sec_4">
        <div className="container section3_ar">
          <div className="sec_4_lahzat">
            <h3>لحظات سفيرا</h3>
            <p>
              استكشف مستقبل المقتنيات مع لحظات سفيرا.
              <br /> لا تفوت فرصة امتلاك أبرز لقطات الرياضة الشهيرة واللحظات
              الأسطورية والمزيد.
            </p>
            <div className="btn_flex_ar_2">
              <JoinWaitlistar />
            </div>
          </div>
          <div className="section7">
            <img src={vector} alt="" />
          </div>
        </div>
      </div>
      <div className="icon_box">
        <div className="box_style">
          <img className="icon_img setimg last_img" src={vector} alt="" />
          <div className="last_box">
            <h3>لحظات سفيرا</h3>
            <p>
              بطاقات تداول رقمية فريدة من نوعها للاعبين وفرق ولحظات أسطورية
              مميزة مختلفة.{" "}
            </p>
          </div>
          <button className="btn_qareeb">قريبًا</button>
        </div>
        <div className="box_style">
          <img className="icon_img" src={icon4} alt="" />
          <div className="">
            <h3>قمصان سفيرا الغير قابلة للأستبدال</h3>
            <p>
              مجموعات المقتنيات الغير قابلة للاستبدال للتعاون المتبادل للاعبين
              والنوادي مع أدوات مساعدة مخصصة.
            </p>

            <br />
            <button className="btn_qareeb"> قريبًا </button>
          </div>
        </div>
        <div className="box_style">
          <img className="icon_img" src={icon2} alt="" />
          <div className="">
            <h3>عملات سفيرا</h3>
            <p>
              العملة المشفرة لـسفيرا هي العملة الأصلية التي تشغل نظام سفيرا
              وتزيد قيمتها من خلال حالات الاستخدام المختلفة ومصادر الإيرادات في
              المنصة
            </p>
            <button className="btn_qareeb">
              {" "}
              <img src={info} /> تعلم
            </button>
          </div>
        </div>
        <div className="box_style box_style_4">
          <img className="icon_img img1" src={Head} alt="" />
          <div className="iner_txt">
            <h3>مقتنيات سفيراهيد</h3>
            <p>
              عناصر جمع رقمية بإصدار محدود "جينيسيس"، تحتوي على استخدامات في{" "}
              <span>.Web2 و Web3 </span>
              <span> 5,000 -NFTS 10,000</span>
              <span> إجمالي </span>{" "}
              <p>
                {" "}
                منحة مجانية و <span>5,000</span>{" "}
                <span> تم بيعها كمجموعة "جينيسيس" .</span>
              </p>{" "}
              <p></p>
            </p>
          </div>
          <button className="btn_qareeb">
            {" "}
            <img src={info} /> تعلم
          </button>
        </div>
      </div>

      <div className="bg-dark new_sectio">
        <CardSectionAr />
        <CardSlider3 />
      </div>
    </div>
  );
}

export default FlexSections;
