import React from "react";
import "../assets/css/onboard.css"
import Logo from '../assets/images/logo.png'
import Onboardtabs from "../components/slid-tabs";


function Slide() {
  return (
    <div>
     <div className="">
   <div>
{/* <img src={Logo} alt="" /> */}
<Onboardtabs />
   </div>
     </div>
               </div>
  );
}

export default Slide;
