import React, { Fragment } from "react";
import Shirts from '../../assets/images/profile/shirts.png';
import HeadStript from '../../assets/images/profile/strip.png';
import Slider from "react-slick";
import '../../assets/css/profile.css';
const Head_Starter = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: true,

  };
  return (
    <Fragment>
      <div>
        <div className='head_starter'>
          <Slider {...settings}>
            <div>
              <div className="headStart_bg">
                <div className="img_sec">
                  <img src={Shirts} alt="" className="shirts_img" />
                </div>
                <div className="img_sec2">
                  <img src={HeadStript} alt="" />
                </div>
                <div className="win_sign">
                  <h3>
                    WIN SIGNED EXCLUSIVE FOOTBALL JERSEYS
                  </h3>
                  <p>JOIN OUR PLATFORM LAUNCH WITH HEADSTARTER TO BE AUTOMATICALLY ENTERED TO WIN SIGNED RONALDO, BENZEMA & NEYMAR JR FOOTBALL JERSEYS!</p>
                </div>
              </div>
            </div>
            <div>
              <h3>2</h3>
            </div>
            <div>
              <h3>3</h3>
            </div>
            <div>
              <h3>4</h3>
            </div>
            <div>
              <h3>5</h3>
            </div>
            <div>
              <h3>6</h3>
            </div>
          </Slider>
        </div>

      </div>

    </Fragment>
  )
}

export default Head_Starter
