import React, { useState } from "react";
import trofii from "../../assets/images/trofii.png";
import Logo2 from "../../assets/images/spera-world.png";
import Foot1 from "../../assets/images/koora 1.png";
import Foot2 from "../../assets/images/logowhite 1.png";
import { FaTwitter, FaTelegramPlane, FaDiscord } from "react-icons/fa";
import "../ar.css/ar_banner.css"


function Ar_Banner() {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => { setIsHovered(true) };
  const handleMouseLeave = () => { setIsHovered(false) };

  return (
    <div className="container-fluid">
      <div className="ar_text">
        <img className="display_top" src={Logo2} alt="" />
        <h1>عالم سفيرا</h1>
        <p className="Ar_para">المقتنيات، معرَّفة مجددًا لعصر الرقمي</p>
        <div className="ar_banner_area">
          <div className="right_text right_text_ar ">
            <img className="sphera_logo" src={Logo2} alt="" />
            <h3>احصل على وصول مبكر إلى منصة سفيرا</h3>
            <p style={{ fontSize: '22px' }}>عالم سفيرا يحدث ثورة في الطريقة التي تقوم بها بجمع المعلومات، تقربك من عالم الرياضة كما لم يحدث من قبل.  احصل على وصول مبكر إلى منصة سفيرا مع رمز الوصول المجاني إلى سفيرا هيد (NFT).</p>
            <button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} > {isHovered ? 'Coming Soon' : 'لمعرفة المزيد'}</button><br />
            <div className=" footer_logo_ar">
              <div>
                <h6>مشغل بواسطة</h6>
                <img src={Foot1} alt="" />
              </div>
              <div className="ml_fix">
                <h6>تم التمكين بواسطة</h6>
                <img src={Foot2} alt="" />
              </div>
            </div>
          </div>
          <div className="left_text_ar">
            <div className="ar_border">
              <div className="ar_trofi_sec">
                <span>اربح الجوائز عن طريق الدخول في السحب على جوائزنا!</span>
                <p>اربح مجموعة مختارة من الجوائز، وانضم إلى حملة الإطلاق الخاصة بنا للمشاركة!</p>
                <img src={trofii} alt="" className="ar_trofi" />
              </div>
            </div>
            <h3 className="social_contact_header">انضم إلى مجتمعنا</h3>
            <div className="join_com_arr join_arr">
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <a href="https://twitter.com/spheraworld" target="_blank" rel="noreferrer" className="social_icon"><FaTwitter size={26} color={"#fff"} /> </a>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <a href="https://discord.com/invite/CwM2H5GUcR" target="_blank" rel="noreferrer" className="social_icon" style={{ backgroundColor: '#5765EC' }}><FaDiscord size={26} color={"#fff"} /></a>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <a href="https://t.me/SpheraWorldPortal" target="_blank" rel="noreferrer" className="social_icon"><FaTelegramPlane size={26} color={"#fff"} /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Ar_Banner;
