// import React, { useState } from 'react';
// import "../assets/css/onboard.css"
// import SlickSlider from './tab-slider';

// const Onboardtabs = () => {
//   const [activeTab, setActiveTab] = useState(1);

//   const handleTabClick = (tabNumber) => {
//     setActiveTab(tabNumber);
//   };

//   return (
//     <div className="vertical-tabs-container">
//       <div className="tab-buttons">
//         <div
//           className={`tab-button ${activeTab === 1 && 'active'}`}
//           onClick={() => handleTabClick(1)}
//         >
       
//         </div>
//         <div
//           className={`tab-button ${activeTab === 2 && 'active'}`}
//           onClick={() => handleTabClick(2)}
//         >
      
//         </div>
//         <div
//           className={`tab-button ${activeTab === 3 && 'active'}`}
//           onClick={() => handleTabClick(3)}
//         >
      
//         </div>
//         <div
//           className={`tab-button ${activeTab === 4 && 'active'}`}
//           onClick={() => handleTabClick(4)}
//         >
   
//         </div>
   
//         {/* Add more tabs as needed */}
//       </div>
//       <div className="tab-content">
//         {/* Content for Tab 1 */}
//         {activeTab === 1 &&
//        <SlickSlider />
        
//         }
//         {/* Content for Tab 2 */}
//         {activeTab === 2 &&
//              <SlickSlider />
//          }
//           {activeTab === 3 &&
//              <SlickSlider />
//          }
//           {activeTab === 4 &&
//              <SlickSlider />
//          }
      
        
//       </div>
//     </div>
//   );
// };

// export default Onboardtabs;




import React, { useState } from 'react';
import '../assets/css/onboard.css';
import Logo from '../assets/images/logo.png';
import SlickSlider from './tab-slider';

const Onboardtabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
  };

  const getBackgroundColor = (index) => {
    switch (index) {
      case 0:
        return 'bg-tab1';
      case 1:
        return 'bg-tab2';
      // Add more cases for additional tabs
      default:
        return '';
    }
  };

  return (
    <div className={`onboard-container ${getBackgroundColor(activeTab)}`}>
      <div className="onboard-flex-container">
        {/* <img src={Logo} alt="Logo" className="logo" /> */}
        
        <div className="tabs">
          <div
            className={`tab ${activeTab === 0 ? 'active' : ''}`}
            onClick={() => changeTab(0)}
          >
            Tab 1
          </div>
          <div
            className={`tab ${activeTab === 1 ? 'active' : ''}`}
            onClick={() => changeTab(1)}
          >
            Tab 2
          </div>
          <div
            className={`tab ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => changeTab(2)}
          >
            Tab 3
          </div>
          {/* Add more tabs as needed */}
        </div>

        <div className="content">
          <div className={`tab-content ${activeTab === 0 ? 'active' : ''}`}>
            {/* Content for Tab 1 */}
            <SlickSlider />
          </div>
          <div className={`tab-content ${activeTab === 1 ? 'active' : ''}`}>
            {/* Content for Tab 2 */}
            <SlickSlider />
          </div>
          <div className={`tab-content ${activeTab === 2 ? 'active' : ''}`}>
            {/* Content for Tab 3 */}
            <SlickSlider />
          </div>
          {/* Add more tab content divs as needed */}
        </div>
      </div>
    </div>
  );
};

export default Onboardtabs;










