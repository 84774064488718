import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../assets/images/logo.png";
import "../ar.css/ar-header.css";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function AR_Header() {
  const [isHovered, setIsHovered] = useState(Array(9).fill(false));

  const handleMouseEnter = (index) => {
    // Update the hover state for the specific link at 'index'
    setIsHovered((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const handleMouseLeave = (index) => {
    // Update the hover state for the specific link at 'index'
    setIsHovered((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  return (
    <Navbar expand="lg" className="header">
      <Container fluid className="header_flex">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <DropdownButton
              id="dropdown-basic-button"
              title="العربية"
              className="drop_down_trans_ar"
            >
              <Dropdown.Item href="/">EN</Dropdown.Item>
            </DropdownButton>
            <Link
              to="#"
              className="open_app"
              style={{ color: "#fff", textDecoration: "none" }}
              onMouseEnter={() => handleMouseEnter(5)}
              onMouseLeave={() => handleMouseLeave(5)}
            >
              {" "}
              {isHovered[5] ? "قريباً" : "              افتح التطبيق"}
            </Link>

            <Nav.Link href="https://t.me/SpheraWorldPortal" target="_blank">
              اتصل
            </Nav.Link>

            <Nav.Link href="https://linktr.ee/spheradoc" target="_blank">
              مستندات
            </Nav.Link>

            <Nav.Link href="https://linktr.ee/spheraworld" target="_blank">
              مجتمع
            </Nav.Link>

            <Nav.Link
              href="#link"
              onMouseEnter={() => handleMouseEnter(6)}
              onMouseLeave={() => handleMouseLeave(6)}
            >
              {isHovered[6] ? "قريباً" : "مجتمع"}
            </Nav.Link>
            <Nav.Link
              href="#link"
              onMouseEnter={() => handleMouseEnter(5)}
              onMouseLeave={() => handleMouseLeave(5)}
            >
              {isHovered[5] ? "قريباً" : "معلومات عنا"}
            </Nav.Link>
            <Nav.Link
              href="#link"
              onMouseEnter={() => handleMouseEnter(4)}
              onMouseLeave={() => handleMouseLeave(4)}
            >
              {isHovered[4] ? "قريباً" : "مدونة"}
            </Nav.Link>
            <Nav.Link
              href="#link"
              onMouseEnter={() => handleMouseEnter(3)}
              onMouseLeave={() => handleMouseLeave(3)}
            >
              {isHovered[3] ? "قريباً" : "التعليمات"}
            </Nav.Link>
            <Nav.Link href="https://app.headstarter.org/" target="_blank">
              INO
            </Nav.Link>
            <Nav.Link
              href="#link"
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={() => handleMouseLeave(1)}
            >
              {isHovered[1] ? "قريباً" : "سفيرا رمز"}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand href="#home">
          <img src={Logo} alt="Logo" />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default AR_Header;
