import React, { useState, useLayoutEffect, Fragment } from 'react'
import Logo from '../../assets/images/logo.png'
import { slide5Data } from '../../Data/onBoard/onBoardData'
import OnBoardSlideCard from '../../components/shared/OnBoardSlideCard'
import { Link } from 'react-router-dom'
import '../ViewStyles.css'


const Slide5 = ({ onStepChange }) => {
  const [onboard, setonboard] = useState();
  const handleNext = () => onStepChange(6);
  const handlePrevious = () => onStepChange(4);

  useLayoutEffect(() => {
    setonboard({
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
      color: '#ffffff',
      backgroundImage: `url(${require('../../assets/images/OnBard/PNG/background5.png')}), url(${require('../../assets/images/OnBard/PNG/line5.png')})`,
      backgroundPosition: '0px 0px, 12% 30px',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundColor: 'transparent',
    })
  }, [])

  return (
    <Fragment>
      <div style={onboard} className='onboard'>
        <div className='onboard_header'>
          <Link to='/'>
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className='onboardcontainer'>
          <div className='left_column'>
            <h1>Earn Rewards and <span className='colortext'>Prizes.</span></h1>
            <p className='desc'>{slide5Data.desc}</p>
          </div>
          <div className='right_column'>
            <div className='box_slide'>
              <OnBoardSlideCard
                heading={slide5Data.heading}
                cheading={slide5Data.cheading}
                desc={slide5Data.cardDesc}
                activeTab={5}
                backgroundImage={require('../../assets/images/slide5.png')}
              />
              <div className='moveButtons'>
                <button onClick={handlePrevious}>Previous</button>
                <button onClick={handleNext}>Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Slide5
