import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/landing-page';
import Slide from './pages/onboarding-slid';
import ArLanding from './AR/ar'
import ConnectWeb3 from './pages/connecting-web3/connect-web3';
import ConnectWeb3Slid2 from './pages/connecting-web3/conect-web3-slid2';
import ConnectWeb3Slid3 from './pages/connecting-web3/conect-web3-slid3';
import Connect from './pages/connecting-web3/connect';
import HederaWallet from './pages/connecting-web3/hedera-wallet';
import SelectWallet from './pages/connecting-web3/select-wallet';
import Signup from './pages/connecting-web3/signup';
import Verify from './pages/connecting-web3/verify';
import AccouctVerified from './pages/connecting-web3/account-verified';
import { OnBoard, AuthOptions, Login, ForgetPassword } from './pages/index';
import Profile from './pages/Profile/Profile';
import 'react-toastify/dist/ReactToastify.css';
import Contact from './pages/Contact/Contact';
import AboutUs from './pages/About/AboutUs';
import PrivateRoutes from './Utils/PrivateRoutes';
import INO from './pages/INO/INO';
import ForgetPasswordOTP from './views/ForgetPassword/ExistingUserOTP.jsx'
import ResetPassword from './views/ForgetPassword/ExistingUserChangePassword'
import OLDConnectWeb3Slid3 from './pages/connecting-web3/Old-connect-web3-slide';


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/ar" element={<ArLanding />} />
          <Route path="/onboard-one" element={<Slide />} />
          <Route path="/AuthOptions" element={<AuthOptions />} />
          <Route path="/onBoard" element={<OnBoard />} />
          <Route path="/connect" element={<ConnectWeb3 />} />
          <Route path="/connect2" element={<ConnectWeb3Slid2 />} />
          {/* old  */}
          <Route path="/blade_redirect" element={<OLDConnectWeb3Slid3 />} />
          {/* new  */}
          <Route path="/redirect_blade" element={<ConnectWeb3Slid3 />} />
          <Route path="/blade_redirect/:param" element={<ConnectWeb3Slid3 />} />
          {/* <Route path="/connect" element={<Connect />} /> */}
          <Route path="/select-wallet" element={<SelectWallet />} />
          <Route path="/hedera-wallet" element={<HederaWallet />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/account-verified" element={<AccouctVerified />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ino" element={<INO />} />
          <Route path="/VerifyOTP" element={<ForgetPasswordOTP />} />
          <Route path="/changePassword" element={<ResetPassword />} />



          {/* Private Routes  */}
          <Route path='/' element={<PrivateRoutes />} >
            <Route path="profile" element={<Profile />} />
          </Route>

        </Routes>
      </Router>
    </div>
  );
}
export default App;
