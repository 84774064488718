import React, { useState, useLayoutEffect, Fragment } from "react";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import "../../views/ViewStyles.css";
import circleLogo from "../../assets/images/circle.png";
import Playerimg from "../../assets/images/bottom-player.png";
import "../../assets/css/conect-web3.css";
import { useNavigate } from "react-router-dom";



const AuthOptions = () => {
    const navigate = useNavigate();
    const [onboard, setonboard] = useState();
    const handleLogin= () => navigate("/login")
    const handleSignup = () => navigate("/onBoard")

    useLayoutEffect(() => {
        setonboard({
            width: "100%",
            height: "100vh",
            overflow: "hidden",
            color: "#ffffff",
            backgroundImage: ` url(${require("../../assets/images/OnBard/PNG/line5.png")})`,
            backgroundPosition: "250px 63px, 34% -12px",
            backgroundRepeat: "no-repeat, no-repeat",
            backgroundColor: "black",
        });
    }, []);

    return (
        <Fragment>
            <div style={onboard} className="onboard">
                <div className="onboard_header">
                    <Link to="/">
                        <img src={Logo} alt="" />
                    </Link>
                    <Link className="arabic_link">العربية</Link>
                </div>
                <div className="onboardcontainer">
                    <div className="left_column left_side">
                       
                    </div>
                    <div className="right_column right_col_signup" style={{ overflow: "visible" }} >
                        <div className=" box_detail">
                            <img src={circleLogo} alt="" className="circle_img circle_img_signup" />
                            <div className="signup_form" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <p style={{ color: "white", fontSize: "30px" }}>Sign in or Create an account</p>
                                <p style={{ color: "white", fontSize: "16px",margin:"10px 0 40px 0" }}>Sphera World revolutionizes the way you collect, connecting you with the world of sports like never before. Get early access to the Sphera platform with the free Sphera Head access NFT.</p>
                                <button onClick={handleLogin}>LogIn</button>
                                <button onClick={handleSignup}>Create New Account</button>
                            </div>
                            <img src={Playerimg} alt="" className="player_img signup_screen_img" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AuthOptions;
