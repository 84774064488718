import { useState, useEffect } from "react";
import { BsArrowUp } from 'react-icons/bs';
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./ar-component/footer";
import ProfileTeam from "./ar-component/team";
import img from "../assets/images/spl.png";
import '../assets/css/popup.css';
import '../assets/css/section.css'
import AR_Header from "./ar-component/header";
import Ar_Banner from "./ar-component/ar-banner";
import Ar_Section from "./ar-component/ar_section";
import FlexSections from "./ar-component/flex-sections";


const ArLanding = () => {
  const [showGoTop, setShowGoTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowGoTop(scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleGoTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  return (
    <div className="main" style={{ width:'100%',overflow:'hidden' }}>
      <button className="gotopbutton" onClick={handleGoTopClick}>
          <BsArrowUp />
        </button>
      <div className="App bg-dark linear_gray">
        <div className="banner_img_ar banner_mobile">
          <AR_Header />
          <Ar_Banner />
        </div>
        <div className="bg-dark ">
          <div className=" main_sec2">
            <Ar_Section />
          </div>
        </div>
      </div>
      <FlexSections />
      <ProfileTeam />
      <Footer />
    </div>
  );
}

export default ArLanding;
