import React from "react";
import "../../assets/css/section.css";
import "../../assets/css/section2.css";
import "../../assets/css/cardsec.css";import Mobile from "../../components/mobile";
import "../ar.css/ar_section2.css"
import JoinWaitlistar from "./Join-waIt-list_ar";
function MobileSec() {
  return (
    <div className="relative_p">
      <div className="mobile_sec">

        <div className="flex_revers">

          <div className="sec4_flex container">

            <div className="sec4_text sec_5 sec5_ar">
              <h3>سفيرا وورلد موبايل</h3>
              <h5>عالم كرة القدم في جيبك.</h5>
              <div className="ul_sec_ar">
                <p className="l-0">
                  ااستكشف واشتري المقتنيات الرقمية والمذكرات والتجارب الحصرية من الرياضيين وكيانات الرياضة:
                   {" "}
                </p>
                <div className="l-0">
                  <li>
                  ابق على اتصال أثناء التنقل: سفيرا وورلد موبايل يبقيك على اتصال مع لحظات الرياضة المفضلة والمقتنيات الرقمية الغير قابلة الأستبدال (NFTs) في أي وقت وفي أي مكان.                  </li>
                  <li>
                    
                    إجراءات الدخول السلسة: اربح مكافآت وجوائز عن طريق الدخول عندما تكون في الملعب أو موقع المباراة.
                  </li>
                  <li>
                  سوق تدوال المقتنيات الرقمية الغير قابلة الأستبدال NFT عبر الهاتف: ادخل إلى سوق سفيرا للعملات المشفرة الرقمية بالكامل من جهازك المحمول.
                  </li>
                  <li>
                  تجربة مستخدم محسنة: لقد صُمم تطبيقنا المحمول لتصفح سلس والتفاعل السهل مع مجموعتك من المقتنيات الرقمية الغير قابلة الأستبدال NFT بسهولة.
                  </li>
                  <li>
                  مزايا حصرية متاحة فقط على الهواتف المحمولة: أحصل على فرص فريدة وعروض ترويجية متاحة فقط على سفيرا وورلد موبايل.
                  </li>
                </div>
                <div className="ar_btn_flex_2 ">
                <JoinWaitlistar />

                </div>
              </div>
            </div>
            <div className="sec4_img ">
              {/* <img src={Mob} alt="" className="img-fluid w-auto" /> */}
              <Mobile />
            </div>
          </div>
        </div>




        <div className="flex_none">
          <div className="sec4_flex container">
            <div className="sec4_img ">
              {/* <img src={Mob} alt="" className="img-fluid w-auto" /> */}
              <Mobile />
            </div>
            <div className="sec4_text sec_5 sec5_ar">
              <h3>سفيرا وورلد موبايل</h3>
              <h5>عالم كرة القدم في جيبك.</h5>
              <div className="ul_sec_ar">
                <p className="l-0">
                  استكشاف وشراء المقتنيات الرقمية، والتذكارات، و
                  تجارب حصرية من الرياضيين والجهات الرياضية
                  : {" "}
                </p>
                <div className="l-0 ul_sec_ar_ul">
                  <li>
                    ابق على اتصال أثناء التنقل: يبقيك Sphera World Mobile على اتصال بلحظاتك الرياضية المفضلة وNFTs، في أي وقت وفي أي مكان.
                  </li>
                  <li>
                    تسجيل الدخول بسلاسة: احصل على المكافآت والجوائز بمجرد التحقق
                    عندما تكون في المباراة أو مكان المباراة.
                  </li>
                  <li>
                    سوق NFT للهواتف المحمولة: يمكنك الوصول إلى سوق Sphera NFT الكامل
                    من جهازك المحمول.
                  </li>
                  <li>
                    تجربة مستخدم محسنة: تم تصميم تطبيق الهاتف المحمول الخاص بنا من أجل
                    التنقل السلس والتفاعل السهل مع NFT الخاص بك
                    مجموعة.
                  </li>
                  <li>
                    ميزات حصرية للجوال فقط: افتح الفرص الفريدة
                    والعروض الترويجية متاحة فقط على Sphera World Mobile
                  </li>
                </div>
                <div className="ar_btn_flex_2">
                  <button className="continue_btn">انضم إلى قائمة الانتظار</button>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileSec;
