import React from "react";
import "../assets/css/cardsec.css";
import info from "../assets/images/info.png";
import logo1 from "../assets/images/logo1.png";
import logo2 from "../assets/images/logo2.png";
import logo3 from "../assets/images/logo3.png";
import Cards from "../assets/images/Group55397.png";
import icon1 from "../assets/images/cards.png";
import icon2 from "../assets/images/icon7.png";
import icon3 from "../assets/images/icon8.png";
import icon4 from "../assets/images/icon9.png";
import lines from "../assets/images/right-line.png";

import Partners from "./partnres";

function CardSection() {
  return (
    <di className="card_div">
      <div className="main_divcard">
        <img src={lines} alt="" className="line-right" />
        <div className="same_div" id="patners">
          <h1>Join the Sphera World</h1>
          <div className="share_icons">
            <a href="#">
              <img src={logo1} className="img-" />
            </a>

            
            <img src={logo2} className="img-" />
            <img src={logo3} className="img-" />
          </div>
        </div>
      </div>
    </di>
  );
}

export default CardSection;
