// import Spline from '@splinetool/react-spline';
import '../assets/css/section.css';
import Spline from '@splinetool/react-spline';

export default function Mobile() {
  return (
    <div>
     <Spline scene="https://prod.spline.design/SWrxQULTZkLnpgoV/scene.splinecode" />
    </div>
  );
}

