import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userData: null,
    ID:1
};

export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        updateUserData: (state, action) => {
            console.log(action);
            state.userData = action.payload;
        },
    },
});

export const { updateUserData } = counterSlice.actions;

export default counterSlice.reducer;
