import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import '../../assets/css/profile.css';
import Trofee from '../../assets/images/profile/trofee.png'
import { Link } from 'react-router-dom';
import BasicTabs from '../../components/leader-board-tabs/Leader_boardTab';
const Leader_BoardRank = () => {

  return (
    <Fragment>
      <div className="leader_board">


        <Grid container spacing={2} >
          {/* col-md-4 equivalent */}
          <Grid item xs={12} md={3} className='grid_col'>
            <Paper>

              <div className='left_leader_col'>
                <div className=''>
                  <div className='tag_title'>
                    <img src={Trofee} alt="" />
                    <span>Leaderboard
                      <br />
                      Rank:
                      <span className='title_tg_num'>
                        #12</span>
                    </span>
                  </div>
                  <p className='text_para'>+3.4%</p>
                </div>
              </div>
            </Paper>
          </Grid>

          {/* col-md-8 equivalent */}
          <Grid item xs={12} md={9} className='grid_col'>
            <Paper>
              <BasicTabs />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  )
}

export default Leader_BoardRank
