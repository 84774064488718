import React, { Fragment, useState } from "react";
import Mask from "../../assets/images/profile/mask.png";
import Container from "@mui/material/Container";
import Icon1 from "../../assets/images/profile/icon-1.png";
import Img1 from '../../assets/images/profile/logo-1.png';
import Img2 from '../../assets/images/profile/logo-2.png';
import Img3 from '../../assets/images/profile/logo-3.png';
import Img4 from '../../assets/images/profile/logo-4.png';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { MdContentCopy } from "react-icons/md";
import { CiCalendar } from "react-icons/ci";
import { LuPencil } from "react-icons/lu";
import Toast_Card, { showToast } from "../../components/common/Toast_Card.jsx";

function ProfileBanner({ name, email, walletID, createdAt, Balance, BalanceInUSD }) {
  const [isWalletVisible, setIsWalletVisible] = useState(false);

  const handleToggleWallet = () => {
    setIsWalletVisible((prevIsVisible) => !prevIsVisible);
  };

  const handleCopyWalletID = () => {
    navigator.clipboard.writeText(walletID)
      .then(() => {
        showToast('WalletID copied to clipboard');
      })
      .catch((error) => {
        console.error('Failed to copy WalletID:', error);
      });
  };

  const renderWalletContent = () => {
    if (isWalletVisible) {
      return (
        <span style={{ color: "#fff" }}>
          {walletID}{" "}
          <button className="hide_side" onClick={handleCopyWalletID}>
            <MdContentCopy />
          </button>
        </span>
      );
    } else {
      return <span style={{ color: "#fff" }}>**********</span>;
    }
  };
  const formattedBalanceInUSD = (BalanceInUSD / 100).toFixed(2);

  return (
    <Fragment>
      <Toast_Card />
      <div className="banner_profile">
        <Container maxWidth="xl">
          <div className="row row_down">
            <div className="col-lg-7">
              <div className="flex_banner">
                <div className="edit_btn">
                  <img src={Mask} alt="" />
                  <button><LuPencil size={35} /></button>
                </div>
                <div>
                  <ul className="ul_flex">
                    <li><h2>{name}</h2></li>
                    <li><img src={Icon1} alt="" srcset="" /></li>
                    <li><h3> Followers<span>: 42.0k </span>{" "}</h3></li>
                    <li><h3>Following<span>: 365 </span>{" "}</h3></li>
                  </ul>
                  <ul className="ul_flex ul_2">
                    <li><h4>{email}</h4></li>
                    <li><h5><CiCalendar color="#FF7F2A" style={{ marginRight: '4px' }} /> {createdAt}</h5></li>
                  </ul>
                  <ul>
                    <li className="ul_flex">
                      <h5> Wallet address </h5>
                      <button className="hide_side" onClick={handleToggleWallet} style={{ marginLeft: "20px" }}>
                        {isWalletVisible ? <IoEyeOffOutline /> : <IoEyeOutline />}
                      </button>
                      {renderWalletContent()}
                    </li>
                  </ul>
                  <p> <span>Bio:</span> Lorem ipsum dolor sit amet consectetur. Viverra duis amet tellus mauris tincidunt habitant consectetur.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="acheivement">
                <h2>Achievements:</h2>
                <div className="acheive">
                  <div className="achieve_imgs">
                    <img src={Img1} alt="" />
                    <img src={Img2} alt="" />
                    <img src={Img3} alt="" />
                    <img src={Img4} alt="" />
                  </div>
                  <div className="wallet_text">
                    <h2>Wallet Balance:</h2>
                    <h1>${formattedBalanceInUSD}</h1>
                    <span>{Balance}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Fragment>
  );
}

export default ProfileBanner;
