import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { Auth_APi } from './Redux/Auth/Auth';
import counterReducer from './Redux/Counter/CounterSlice.js'
import App from './App.jsx';

const store = configureStore({
  reducer: {
    counter: counterReducer,
    [Auth_APi.reducerPath]: Auth_APi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(Auth_APi.middleware),
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
