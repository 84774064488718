import React from 'react';
import Header from '../../components/header';
import GetTouch from '../../views/Contact/GetTouch';
import { SubscribeContact } from '../../views';
import '../../assets/css/contact.css';
import Footer from '../../components/footer';

const Contact = () => {
  return (
    <div className='contact' style={{backgroundColor:'#0f0f0f'}}>
      <Header />
      <GetTouch />
      <div className='subscribe_contact'>
      <SubscribeContact  />
      </div>
      <Footer />
      </div>
  )
}

export default Contact
