import React, { useState } from 'react'
import {
    ForgetPasswordEmail, ResetPassword, ForgetPasswordOTP
} from '../../views/index'
import '../Styles.css'
import { useNavigate } from 'react-router-dom';

const ForgetPassword = () => {
    const [step, setStep] = useState(1);
    const navigate = useNavigate();


    const stepActionHandler = (data) => {
        if (data === 4) { navigate('/login'); }
        else { setStep(data) }
    };


    return (
        <div className='onboard_page'>
            <div >
                {step === 1 && (
                    <ForgetPasswordEmail onStepChange={stepActionHandler} />
                )}
                {step === 2 && (
                    <ForgetPasswordOTP onStepChange={stepActionHandler} />
                )}
                {step === 3 && (
                    <ResetPassword onStepChange={stepActionHandler} />
                )}
            </div>
        </div>
    )
}

export default ForgetPassword
