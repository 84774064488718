import React, { useState, useLayoutEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import circleLogo from "../../assets/images/circle.png";
import Playerimg from "../../assets/images/bottom-player.png";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { useForgetPasswordEmailMutation } from "../../Redux/Auth/Auth";
import "../../assets/css/conect-web3.css";
import "../../views/ViewStyles.css";
import Toast_Card, { showToast } from "../../components/common/Toast_Card.jsx"
import { BsArrowRight } from "react-icons/bs";
import RingLoader from "react-spinners/RingLoader";
import Btn from '../../components/shared/Btn.jsx'


const ForgetPasswordEmail = ({ onStepChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ email: "" });
  const navigate = useNavigate();



  const [onboard, setonboard] = useState();
  useLayoutEffect(() => {
    setonboard({
      width: "100%",
      height: "100vh",
      overflow: "hidden",
      color: "#ffffff",
      backgroundImage: ` url(${require("../../assets/images/OnBard/PNG/line5.png")})`,
      backgroundPosition: "250px 63px, 34% -12px",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundColor: "black",
    });
  }, []);


  // Query 
  const [ ForgetPasswordEmail ] = useForgetPasswordEmailMutation();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleForgetPasswordEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    onStepChange(2);
    setIsLoading(false);
    
    
    try {
      const UserData = { email: formData.email };
      console.log("forgetpassword", UserData);
      const res = await ForgetPasswordEmail(UserData);
      console.log(res);
      if (res.error) {
        setIsLoading(false);
        showToast('Something wrong on sever', 'error');
      } else {
        sessionStorage.setItem("resetEmail", UserData.email);
        console.log(UserData.email);
        setIsLoading(false);
        // onStepChange(2);
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
      showToast('An error occurred. Please try again later.', 'error');
    }
  };



  return (
    <Fragment>
      <Toast_Card />
      <div style={onboard} className="onboard">
        <div className="onboard_header">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
          <Link className="arabic_link">العربية</Link>
        </div>
        <div className="onboardcontainer">
          <div className="left_column left_side">
          </div>
          <div className="right_column right_col_signup" style={{ overflow: "visible" }}  >
            <div className=" box_detail">
              <img src={circleLogo} alt="" className="circle_img circle_img_signup" />
              <div className="signup_form">
                <h3>Forget Password </h3>
                <span className="email_para">
                  Email <Link>WhatsApp number</Link>
                </span>
                <br />
                <form onSubmit={handleForgetPasswordEmail}>
                  <div style={{ marginBottom: "20px" }}>
                    <label htmlFor="Name">Email</label>
                    <input type="text" name="email" id="email" value={formData.email} onChange={handleInputChange} required />
                  </div>
                  <Btn text="Send OTP" disabled={isLoading} icon={
                    !isLoading ? <BsArrowRight style={{ fontSize: '16px', color: '#FFF' }} /> : <RingLoader color={"#FFFFFF"} loading={true} size={24} />}
                    type="submit"
                    style={{ backgroundColor: '#007BFF', color: '#fff', fontSize: '14px', height: '40px', margin: 'auto', display: 'block' }} />
                </form>
                <p className="para_link">Already account.<Link to={"/login"}>LogIn</Link></p>
              </div>
              <img src={Playerimg} alt="" className="player_img signup_screen_img" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgetPasswordEmail;
