import React from "react";
import "../assets/css/gettouch.css";
import arrow from "../assets/images/arrow-right.svg";


function GetTouch() {
  return (
    <div className="gettouch">
      <h1>Let’s get in touch</h1>
      <p>We’d love to hear from you. Please fill out this form.</p>
      <button>Contact us <img src={arrow} alt="" /></button>
    </div>
  );
}

export default GetTouch;
