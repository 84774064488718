import React, { useState } from 'react'
import {
  Slide1, Slide2, Slide3,
  Slide4, Slide5, Slide6
} from '../../views/index'
import '../Styles.css'
import { useNavigate } from 'react-router-dom';

const OnBoard = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();


  const stepActionHandler = (data) => {
    if (data === 7) { navigate('/signup');}
    else { setStep(data) }
  };


  return (
    <div className='onboard_page'>
      <div >
        {step === 1 && (
          <Slide1 onStepChange={stepActionHandler} />
        )}
        {step === 2 && (
          <Slide2 onStepChange={stepActionHandler} />
        )}
        {step === 3 && (
          <Slide3 onStepChange={stepActionHandler} />
        )}
        {step === 4 && (
          <Slide4 onStepChange={stepActionHandler} />
        )}
        {step === 5 && (
          <Slide5 onStepChange={stepActionHandler} />
        )}
        {step === 6 && (
          <Slide6 onStepChange={stepActionHandler} />
        )}
      </div>
    </div>
  )
}

export default OnBoard
