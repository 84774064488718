import React, {useState, useLayoutEffect, Fragment } from 'react'
import Logo from '../../assets/images/logo.png'
import { slide4Data } from '../../Data/onBoard/onBoardData'
import OnBoardSlideCard from '../../components/shared/OnBoardSlideCard'
import { Link } from 'react-router-dom'
import '../ViewStyles.css'


const Slide4 = ({ onStepChange }) => {
  const [onboard, setonboard] = useState();
  const handleNext = () => onStepChange(5);
  const handlePrevious = () => onStepChange(3);

  useLayoutEffect(() => {
    setonboard({
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
      color: '#ffffff',
      backgroundImage: `url(${require('../../assets/images/OnBard/PNG/background4.png')}), url(${require('../../assets/images/OnBard/PNG/line4.png')})`,
      backgroundPosition: '0px 0px, 12% 30px',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundColor: 'transparent',
    })
  }, [])

  return (
    <Fragment>
      <div style={onboard} className='onboard'>
        <div className='onboard_header'>
          <Link to='/'>
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className='onboardcontainer'>
          <div className='left_column'>
            <h1>Connect with your favourite <span className='colortext'>Clubs and Players.</span></h1>
            <p className='desc'>{slide4Data.desc}</p>
          </div>
          <div className='right_column'>
            <div className='box_slide'>
              <OnBoardSlideCard
                heading={slide4Data.heading}
                cheading={slide4Data.cheading}
                desc={slide4Data.cardDesc}
                activeTab={4}
                backgroundImage={require('../../assets/images/slide4.png')}
              />
              <div className='moveButtons'>
                <button onClick={handlePrevious}>Previous</button>
                <button onClick={handleNext}>Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Slide4
