export const slide1Data ={
    desc :'Become the league champion of the world of Web3.',
    heading:'Connecting Football to the',
    cheading:'world of Web3',
    cardDesc:'The Future of fan engagement and sports incentives is built on Web3. Connect to your favourite teams in a new way through Sphera World.'
}

export const slide2Data ={
    desc :'Connect with friends and make new friends throughout the world of football.',
    heading:'Join a global community of',
    cheading:'Football enthusiasts.',
    cardDesc:'Own iconic football moments as digital collectibles, Collect and trade exclusive digital memorabilia.'
}

export const slide3Data ={
    desc :'Own iconic football moments as digital collectibles, Collect and trade exclusive digital memorabilia.',
    heading:'Find a new passion for',
    cheading:'Collecting Digital Assets.',
    cardDesc:'Own iconic football moments as digital collectibles, Collect and trade exclusive digital memorabilia.'
}

export const slide4Data ={
    desc :'Access collections from some of your favourite star players & clubs in the fastest growing leagues in the Middle East and Africa.',
    heading:'Own memorabilia from your',
    cheading:'favourite players.',
    cardDesc:'Connect in new ways with your favourite players and clubs through Sphera World.'
}

export const slide5Data ={
    desc :'Earn Digital Collectibles, Memorabilia and enter prize draws and raffles for exclusive items.',
    heading:'Find a new passion for',
    cheading:'Collecting Digital Assets.',
    cardDesc:'Own iconic football moments as digital collectibles, Collect and trade exclusive digital memorabilia.'
}

export const slide6Data ={
    desc :'Setup your Sphera World profile today to unlock the full platform.',
    heading:'Create your account and setup',
    cheading:'your Web3 Wallet.',
    cardDesc:'To access the Sphera World platform you’ll need to create an account and will be provided with a wallet to manage your digital collectibles and tokens.'
}