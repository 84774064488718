import React from "react";
import "../assets/css/team.css";
import card from "../assets/images/card1.png";
import card2 from "../assets/images/card2.png";
import card3 from "../assets/images/card3.png";
import card4 from "../assets/images/newimg1.png";
import card5 from "../assets/images/newimg2.png";
import GetTouch from "./gettouch";
import Faq from "./faq";
import Subscription from "./subscription";

function ProfileTeam() {
  return (
<div className="main_team">
    <div className="Profileteam">
      <h1>Our Advisors</h1>
      <div className="profile_main">
        <div className="profile">
          <img src={card} alt="" className="img-fluid"/>
          <p>Mikaël Silvestre<br />Former French Football Player</p>
        </div>
        <div className="profile">
          <img src={card2} alt="" className="img-fluid" />
          <p>Walid Abou Zaki<br />Founder at UnlockBC</p>
        </div>
        <div className="profile">
          <img src={card3} alt="" />
          <p>Bruno Santos<br />Football Agent</p>
        </div>
        <div className="profile space">
          <img src={card4} alt="" />
        </div>
        <div className="profile space">
          <img src={card5} alt="" />
        </div>
      </div>
    </div>
    <GetTouch />
    <Faq />
    <Subscription />
    </div>
  );
}

export default ProfileTeam;
