import React from "react";
import "../../assets/css/team.css";
import card from "../../assets/images/card1.png";
import card2 from "../../assets/images/card2.png";
import card3 from "../../assets/images/card3.png";
import card4 from "../../assets/images/newimg1.png";
import card5 from "../../assets/images/newimg2.png";
import "../ar.css/team.css";
import GetTouch from "./gettouch";
import Faq from "./faqs";
import Subscription from "./subcription";
function ProfileTeam() {
  return (
    <div className="main_team">
      <div className="Profileteam_ar">
        <h1 style={{ width: "90%", textAlign: 'right' }}>مستشارينا</h1>
        <div className="profile_main">
          <div className="profile">
            <img src={card} alt="" className="img-fluid" />
            <p>ميكائيل سيلفستر<br /> لاعب كرة قدم فرنسي سابق</p>
          </div>
          <div className="profile">
            <img src={card2} alt="" className="img-fluid" />
            <p>وليد ابو زكي <br />مؤسس في الغاء القفل</p>
          </div>
          <div className="profile">
            <img src={card3} alt="" />
            <p>برونو سانتوس<br /> وكيل كرة القدم</p>
          </div>
          <div className="profile_arspace">
            <img src={card4} alt="" />
          </div>
          <div className="profile_arspace">
            <img src={card5} alt="" />
          </div>
        </div>
      </div>
      <GetTouch />
      <Faq />
      <Subscription />
    </div>
  );
}

export default ProfileTeam;
