import React from 'react';
import Subscription from '../../components/subscription';
import Img from '../../assets/images/Contact/contact.png';
import Container from '@mui/material/Container';
const SubscribeContact = () => {
  return (
    <Container >
      <div className='blur_spot2'></div>
    <div className='contact_subscribe'>
      <div style={{display:'flex'}}>
<div className='subscrip_zidex'>

<div className="subcription container">
      <h1>Subscribe <br /> to our newsletter</h1>
      <p>Join our community to learn about exclusive deals and latest news</p>
      <form action="https://spheraworld.us8.list-manage.com/subscribe/post?u=00477a26a8ab091e787afccf8&amp;id=f11f811364&amp;f_id=005f70e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
   
      <div className="flex_input">
        <input name="EMAIL" class="required email" id="mce-EMAIL"  type="text" placeholder="Enter Your Email Address" className="inp_sub" />
        <button type="submit" name="subscribe" id="mc-embedded-subscribe">Subscribe</button>

      </div>
      {/* <StepPopup />   */}
   </form>   
      </div>
</div>
<div>
  <img src={Img} alt="" />
</div>
      </div>
    
    </div>
    </Container>
  )
}

export default SubscribeContact
