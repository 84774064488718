import React from "react";
import "../assets/css/section.css";
import "../assets/css/section2.css";
import "../assets/css/cardsec.css";
import Mobile from "./mobile";
import JoinWaitlist from "./join-waitlist";

function Mobile_Replace() {
  return (
    <div className="relative_p">
      <div className="mobile_sec">
        <div className="sec4_flex container">
          <div className="sec4_img ">
            {/* <img src={Mob} alt="" className="img-fluid w-auto" /> */}
            <Mobile />
          </div>
          <div className="sec4_text sec_5">
            <h3>Sphera World Mobile</h3>
            <h5>The World of Football in your pocket.</h5>
            <div className="ul_sec">
              <p className="l-0 ul_sec_p">
                Explore and purchase digital collectibles, memorabilia, and
                exclusive experiences from athletes and sports entities : {" "}
              </p>
              <div style={{ color: "#fff", margin:'auto',display:'block',width:'fit-content' }}>
                <li>
                  Stay Connected on the Go: Sphera World Mobile keeps you in
                  touch with your favorite sports moments and NFTs, anytime and
                  anywhere.
                </li>
                <li>
                  Seamless Check-Ins: Earn rewards and prizes by simply checking
                  in when you're at the game or match venue.
                </li>
                <li>
                  Mobile NFT Marketplace: Access the full Sphera NFT marketplace
                  from your mobile device.
                </li>
                <li>
                  Enhanced User Experience: Our mobile app is designed for

                  smooth navigation and easy interaction with your NFT
                  collection.
                </li>
                <li>
                  Exclusive Mobile-Only Features: Unlock unique opportunities
                  and promotions available only on Sphera World Mobile.
                </li>
              </div>
              <div className="btn_flex_1">
              <JoinWaitlist />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mobile_Replace;
