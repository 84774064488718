import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/patners.css";
import slidOne from "../../assets/images/Group 4 (1).png";
import slidTwo from "../../assets/images/slid-one.png";
import slidThree from "../../assets/images/slid-3.png";
import slidfour from "../../assets/images/slid-4.png";
import slidfive from "../../assets/images/slid-5.svg";
import slidsix from "../../assets/images/slid-6.svg";
import slidse from "../../assets/images/slid-7.png";

import slideg from "../../assets/images/slid-8.png";

import slidni from "../../assets/images/slid-9.png";

const CardSlider3 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    arrows: false, // Hide the arrows
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    centerPadding: "60px", 
    autoplay: true, // Add autoplay option
    autoplaySpeed: 3000, // Set autoplay speed in milliseconds (e.g., 3000ms for 3 seconds between slides)

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="coursel_main container">
        <h1>شركاؤنا</h1>
      </div>
      <Slider {...settings} className="mt-2">
        <div className="card">
          <img src={slidOne} alt="" />
        </div>
        <div className="card">
          <img src={slidTwo} alt="" />
        </div>

        <div className="card">
          <img src={slidThree} alt="" />
        </div>

        <div className="card">
          <img src={slidfour} alt="" />
        </div>

        <div className="card">
          <img src={slidse} alt="" />
        </div>
        <div className="card">
          <img src={slideg} alt="" />
        </div>
        <div className="card">
          <img src={slidfive} alt="" />
        </div>
        <div className="card">
          <img src={slidni} alt="" />
        </div>
        <div className="card">
          <img src={slidsix} alt="" />
        </div>
        
       
       
        {/* Add more cards */}
      </Slider>
    </div>
  );
};

export default CardSlider3;
