import React from "react";
import logo1 from "../../assets/images/logo1.png";
import logo2 from "../../assets/images/logo2.png";
import logo3 from "../../assets/images/logo3.png";
import lines from "../../assets/images/right-line.png";
import "../../assets/css/cardsec.css";

function CardSectionAr() {
  return (
    <di className="card_div">
      <div className="main_divcard">
        <img src={lines} alt="" className="line-right" />
        <div className="same_div" id="patners">
          <h1>انضم إلى عالم سفيرا</h1>
          <div className="share_icons">
            <a href="#">
              <img src={logo1} className="img-" />
            </a>
            <img src={logo2} className="img-" />
            <img src={logo3} className="img-" />
          </div>
        </div>
      </div>
    </di>
  );
}

export default CardSectionAr;
