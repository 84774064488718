import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/faq.css";
import "../ar.css/faq.css"

function Faq() {
  // Define the FAQ data as an array of objects
  const [expanded, setExpanded] = useState(0);

  const faqData = [
    {
      question: "ما هو عالم سفيرا؟",
      answer:
        "عالم سفيرا هو نظام ثوري يدمج عالم الرياضة وتكنولوجيا البلوكشين والمقتنيات الرقمية. يتيح للمشجعين التفاعل مع فرقهم الرياضية المفضلة واللاعبين واللحظات بطريقة جديدة تمامًا.",
    },
    {
      question: "ما هي تقنية والبلوكتشين؟  Web3  ",
      answer:
        "إذا كنت جديدًا على Web3 والبلوكتشين، فلا تقلق - عالم سفيرا يجعل الأمور سهلة للمبتدئين. تقنية البلوكتشين هي تقنية سجل موزع تضمن الشفافية والأمان في المعاملات الرقمية."
    },
    {
      question: "كيف يمكنني البدء مع عالم سفيرا؟",
      answer: "للبدء، ما عليك سوى التسجيل على منصتنا، واستكشاف المقتنيات الرقمية، والانضمام إلى مجتمعنا، والبدء في الاستفادة من فوائد نظام عالم سفيرا .",
    },
    {
      question: " ما هي عملة سفيرا؟ (SPT)",
      answer: "يمكنك كسب وتداول واستخدام SPT في أنشطة متنوعة داخل المنصة. عملة سفيرا (SPT) هي عملتنا الأصلية التي تشغل نظام عالم سفيرا.",
    },
    {
      question: " أين يمكنني العثور على مزيد من المعلومات والدعم؟",
      answer: "زر مركز المساعدة لدينا للإرشادات المفصلة والدعم، أو اتصل بفريق خدمة العملاء للمساعدة.",
    },
    {
      question: " ما هي محفظة العملات المشفرة، ولماذا أحتاج إليها؟",
      answer: "محفظة العملات المشفرة هي أداة رقمية تتيح لك تخزين وإرسال واستقبال العملات المشفرة والـ NFTs. تحتاج إلى محفظة لإدارة أصولك الرقمية بأمان والمشاركة في معاملات البلوكتشين على عالم سفيرا .",
    },
    {
      question: "كيف يمكنني التواصل مع عشاق عالم سفيرا الآخرين؟",
      answer: "انضم إلى مجتمعنا على Discord، وتابعنا على وسائل التواصل الاجتماعي، وشارك في أحداثنا للتواصل مع مستخدمي عالم سفيرا الآخرين.",
    },
  ];
  const toggleFaq = (index) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };

  return (
    <div className="faq container" id='faq'>
      <div className="ar_faq">
        <div className="faqs">
          {faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question_ar" onClick={() => toggleFaq(index)}>
                <FontAwesomeIcon icon={expanded === index ? faMinus : faPlus} /> {item.question}</div>
              {expanded === index && (
                <div className="faq-answer_ar">{item.answer}</div>
              )}
            </div>
          ))}
        </div>
        <div className="faq_head">
          <h1 className="faq_heading_h1">الأسئلة الشائعة</h1>
          <p>نحن هنا للمساعدة.</p>
        </div>
      </div>
    </div>
  );
}

export default Faq;
