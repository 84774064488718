import React from "react";
import "../assets/css/section.css";
import "../assets/css/section2.css";
import "../assets/css/cardsec.css";
import Laptop from "../assets/images/new_lp.png";
import Line2 from "../assets/images/pngline.png";
import CardSection from "./card";
import Responsive from "./partnres";
import info from "../assets/images/info.png";
import icon1 from "../assets/images/cards.png";
import icon2 from "../assets/images/icon7.png";
import icon3 from "../assets/images/icon8.png";
import icon4 from "../assets/images/icon9.png";
import CardSlider3 from "./partnres";
import vector from "../assets/images/subject.png";
import Head from "../assets/images/heads.png";
import JoinWaitlist from "./join-waitlist";

function Section2() {
  return (
    <div className="relative_p">
      <div className="sec_4">
        <div className="container">
          <div className="flex_revers">
            <div className="row sec4_flex">
              <div className="col-lg-6 col-md-12 sec4_text">
                <h3>Sphera World Marketplace</h3>
                <h5>Collect. Connect. Thrive.</h5>
                <div className="ul_sec">
                  <p>
                    Explore and purchase digital collectibles, memorabilia, and
                    exclusive <br /> experiences from athletes and sports entities :
                  </p>
                  <div style={{ color: '#fff',display:'block',width:'fit-content' }}>
                    <li>Center of Sphera World ecosystem</li>
                    <li>NFT trading platform</li>
                    <li>Fueled by $Sphera Tokens</li>
                  </div>
                  <div className="btn_flex">
                  <JoinWaitlist />
                  </div>
                </div>
              </div>
              <div className="col-md-12 laptop_sec col-lg-6">
                <img src={Laptop} alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="flex_none">
            <div className="row sec4_flex  ">
              <div className="col-md-12 col-lg-6  laptop_sec">
                <img src={Laptop} alt="" className="img-fluid" />
              </div>
              <div className="col-md-12 col-lg-6 sec4_text">
                <h3>Sphera World Marketplace</h3>
                <h5>Collect. Connect. Thrive.</h5>
                <div className="ul_sec">
                  <p>
                    Explore and purchase digital collectibles, memorabilia, and
                    exclusive <br /> experiences from athletes and sports entities :
                  </p>
                  <div style={{ color: '#fff', textAlign:'left', marginLeft:"20px" }}>
                    <li>Center of Sphera World ecosystem</li>
                    <li>NFT trading platform</li>
                    <li>Fueled by $Sphera Tokens</li>
                  </div>

                  <div className="btn_flex">
                  <JoinWaitlist />
                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>

      </div>
      <img src={Line2} alt="" className="line2" />

      <div className=" sec_4">
        <div className="container section3">

          <div className="section7">
            <img src={vector} alt="" />
          </div>
          <div>
            <h3>Sphera Moments™</h3>
            <p>
              Step into the future of collectibles with Sphera Moments.
              <br /> Don't miss out on owning iconic sports highlights,
              <br /> legendary plays, and more.
            </p>
            <div className="btn_flex">
            <JoinWaitlist />
            </div>
          </div>
        </div>

      </div>
      <div className="icon_box">
        <div className="box_style">
          <img className="icon_img img1" src={Head} alt="" />
          <div className="iner_txt">
            <h3>SpheraHead NFTs</h3>
            <p>
              Limited edition "Genesis" digital sports collectibles, that has Web2
              & Web3 utilities. Total 10,000 NFTS - 5,000 Free Airdrop and 5,000
              sold as a "Genesis" collection.
            </p>
          </div>
          <button>
            {" "}
            <img src={info} /> Learn
          </button>
        </div>
        <div className="box_style">
          <img className="icon_img" src={icon2} alt="" />
          <div className="">
            <h3>Sphera Tokens</h3>
            <p>
              Sphera token is the native token that fuels the SW ecosystem and its
              value increasesthrough the platform's different use casesand revenue
              streams
            </p>
            <button >
              {" "}
              <img src={info} /> Learn
            </button>
          </div>
        </div>
        <div className="box_style">
          <img className="icon_img" src={icon4} alt="" />
          <div className="">
            <h3>Sphera Jerseys NFTs</h3>
            <p>
              Cross-collaboration NFT collections for players and clubs as
              collectibles with custom utilities.
            </p>

            <br />
            <button >
              {" "}

              Coming soon
            </button>
          </div>

        </div>
        <div className="box_style">
          <img className="icon_img setimg last_img" src={vector} alt="" />
          <div className="last_box">
            <h3>Sphera Moments</h3>
            <p>joi
              Unique digital collectible trading cards of different players, teams and iconic legendary moments.
            </p>
          </div>
          <button >
            {" "}
            Coming soon
          </button>

        </div>
      </div>

      <div className="bg-dark new_sectio">
        <CardSection />
        <CardSlider3 />
      </div>
    </div>
  );
}

export default Section2;
