import React, { useState, useLayoutEffect, Fragment } from 'react'
import Logo from '../../assets/images/logo.png'
import { slide1Data } from '../../Data/onBoard/onBoardData'
import OnBoardSlideCard from '../../components/shared/OnBoardSlideCard'
import { Link } from 'react-router-dom';
import '../../views/ViewStyles.css';
import circleLogo from '../../assets/images/circle.png';
import Playerimg from '../../assets/images/bottom-player.png';
import '../../assets/css/conect-web3.css';
import Blade from '../../assets/images/blad.png'
const SelectWallet = () => {
 
  
  
  const [onboard, setonboard] = useState();
  useLayoutEffect(() => {
    setonboard({
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
      color: '#ffffff',
      backgroundImage: ` url(${require('../../assets/images/OnBard/PNG/line1.png')})`,
      backgroundPosition: '250px 63px, 34% -12px',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundColor: 'black',
    })
  }, [])


  return (
    <Fragment>
      <div style={onboard} className='onboard'>
        <div className='onboard_header'>
          <Link to='/'>
            <img src={Logo} alt="" />
          </Link>
          <Link className='arabic_link'>العربية</Link>
        </div>
        <div className='onboardcontainer'>
          <div className='left_column left_side'>
            <h1>Connecting to .<span className='colortext'> Web3</span></h1>
            <p className='desc'>Chose how you would like to connect.</p>
          </div>
          <div className='right_column'  style={{overflow:'visible'}}>
            <div className=' box_detail'>
              <img src={circleLogo} alt="" className='circle_img'/>
          <div className='center_box_web3'>
            <div>
            <h2>Select Wallet Setup</h2>
          <p>To fully access the Sphera World platform its important to chose how you would like to manage your assets..</p>
          <div className='d-flex'>
          <Link className='btn_create' to='/hedera-wallet' >Next</Link>
          </div>
        

        
            </div>

          </div>
          <img src={Playerimg} alt="" className='player_img'/>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default SelectWallet
