import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../ComponentStyles.css';

const OnBoardSlideCard = ({ heading, cheading, desc, activeTab, backgroundImage }) => {
  const [ SliderCard, setSliderCard ] = useState(); 

  useLayoutEffect(() => {
    setSliderCard({
      border: '3px solid gray',
      backgroundColor: '#1B1B1B',
      borderRadius: '10px',
      boxShadow: '0px 0px 8px 1px #FF7F2A',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    })
  }, [])

  return (
    <div className='onBoardSlide'>
      <div style={SliderCard} className='SliderCard'>
        <div className='textArea'>
          <h1>
            {heading} <span className='colortext'>{cheading}</span>
          </h1>
          <p>{desc}</p>
        </div>
      </div>
      <div className='lineRow'>
        {Array.from({ length: 6 }, (_, index) => (
          <div
            key={index}
            className={index + 1 === activeTab ? 'activeLine' : 'linee'}
          ></div>
        ))}
      </div>
    </div>
  );
};

OnBoardSlideCard.propTypes = {
  heading: PropTypes.string.isRequired,
  cheading: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  activeTab: PropTypes.number.isRequired,
  backgroundImage: PropTypes.string.isRequired,
};

export default OnBoardSlideCard;
