import React, { useState, useEffect } from "react";
import {
  ProfileHeader, ProfileBanner, Head_Starter
} from '../../views/index'
import "../../assets/css/profile.css";
import Leader_BoardRank from "../../views/Profile/Leader_BoardRank";
import { useUserAccountInformationQuery } from "../../Redux/Auth/Auth";


const Profile = () => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUser = sessionStorage.getItem('userData');
        setUserId(storedUser);
      } catch (error) {
        console.error("An error occurred during fetching user information:", error);
      }
    };

    fetchData();
  }, []);

  // Query
  console.log("userId", userId);
  const { data, isLoading, isError, refetch } = useUserAccountInformationQuery(userId);

  console.log("Data:", data);

  const getOrdinalSuffix = (number) => {
    const j = number % 10;
    const k = number % 100;

    if (j === 1 && k !== 11) {
      return number + "st";
    }
    if (j === 2 && k !== 12) {
      return number + "nd";
    }
    if (j === 3 && k !== 13) {
      return number + "rd";
    }
    return number + "th";
  };
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    const day = getOrdinalSuffix(date.getDate());
    return date.toLocaleDateString('en-US', options).replace(/\d+/, day);
  };

  console.log(data?.balanceInUSD);

  return (
    <div>
      <ProfileHeader />
      <ProfileBanner
        name={data?.user?.name}
        email={data?.user?.email}
        walletID={data?.user?.walletID}
        createdAt={formatDate(data?.user?.createdAt)}
        Balance={data?.actualHbarBalance}
        BalanceInUSD={data?.balanceInUSD}
      />
      <Head_Starter />
      {/* <Leader_BoardRank /> */}
    </div>
  );
}

export default Profile;
