import React, { useState, useLayoutEffect, Fragment } from "react";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import circleLogo from "../../assets/images/circle.png";
import Playerimg from "../../assets/images/bottom-player.png";
import { useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../../Redux/Auth/Auth";
import "../../assets/css/conect-web3.css";
import "../../views/ViewStyles.css";
import Toast_Card, { showToast } from "../../components/common/Toast_Card.jsx"
import { BsArrowRight } from "react-icons/bs";
import RingLoader from "react-spinners/RingLoader";
import Btn from '../../components/shared/Btn.jsx'
import { useSelector, useDispatch } from 'react-redux';
import { updateUserData } from '../../Redux/Counter/CounterSlice.js';



const Signup = () => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.counter.userData);
  const dispatch = useDispatch();
  
  const [onboard, setOnboard] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", password: "" });

  useLayoutEffect(() => {
    sessionStorage.clear();
    setOnboard({
      width: "100%",
      height: "100vh",
      overflow: "hidden",
      color: "#ffffff",
      backgroundImage: ` url(${require("../../assets/images/OnBard/PNG/line5.png")})`,
      backgroundPosition: "250px 63px, 34% -12px",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundColor: "black",
    });
  }, []);


  // Query 
  const [RegisterUser] = useRegisterMutation();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const UserData = {
      Name: formData.name,
      email: formData.email,
      password: formData.password
    }
    try {
      const res = await RegisterUser(UserData);
      if (res.error) {
        if (res.error.status === 500) { setIsLoading(false); return showToast('Sever Error', 'error') }
        else if (res.error.status === 400) { setIsLoading(false); return showToast('Email Already Exist', 'error') }
        else { setIsLoading(false); return showToast('Server not responding', 'error') }
      }
      const User = res?.data?.user;
      dispatch(updateUserData(User));
      localStorage.setItem('user', JSON.stringify(User));
      setIsLoading(false);
      navigate("/verify")
    } catch (error) {
      console.error("An error occurred during login:", error);
      showToast('An error occurred. Please try again later.', 'error');
    }
  };



  return (
    <Fragment>
      <Toast_Card />
      <div style={onboard} className="onboard">
        <div className="onboard_header">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
          <Link className="arabic_link">العربية</Link>
        </div>
        <div className="onboardcontainer">
          <div className="left_column left_side">
            <h1>Sign Up.</h1>
            <p className="desc">Sign up to Sphera World with your email account.</p>
          </div>
          <div className="right_column right_col_signup" style={{ overflow: "visible" }} >
            <div className=" box_detail">
              <img src={circleLogo} alt="" className="circle_img circle_img_signup" />
              <div className="signup_form">
                <h3>Sign up</h3>
                <span className="email_para">Email <Link>WhatsApp number</Link></span>
                <br />
                <form onSubmit={handleSignup}>
                  <div className="input_sec">
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" id="name" value={formData.name} onChange={handleInputChange} required />
                  </div>
                  <div>
                    <label htmlFor="Email">Email</label>
                    <input type="email" name="email" id="email" value={formData.email} onChange={handleInputChange} required />
                  </div>
                  <div>
                    <label htmlFor="Password">Password</label>
                    <input type="password" name="password" id="password" value={formData.password} onChange={handleInputChange} required />
                  </div>
                  <p className="para_link">
                    By clicking the button, you agree to the{" "}
                    <Link>Terms of Service</Link>.
                  </p>
                  <Btn text="Sign Up" disabled={isLoading} icon={
                    !isLoading ? <BsArrowRight style={{ fontSize: '16px', color: '#FFF' }} /> : <RingLoader color={"#FF7F2A"} loading={true} size={24} />}
                    type="submit"
                    style={{ backgroundColor: '#007BFF', color: '#fff', fontSize: '14px', height: '40px', margin: 'auto', display: 'block' }} />
                </form>
                <p className="para_link">
                  You already have an account ? <Link to='/login'>Log in.</Link>{" "}
                </p>
              </div>
              <img src={Playerimg} alt="" className="player_img signup_screen_img" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Signup;
