import React from 'react'
import '../../assets/css/contact.css';
const GetTouch = () => {
  return (
    <div  className='contact_get_touch'>
      <h5>Contact</h5>
      <h2>Get in touch</h2>
      <p>We’d love to hear from you. Please fill out this form.</p>
      <div className='blur_spot'></div>
      <form action="">
        <div>
            <label htmlFor="Name">Name</label>
            <input type="text" name="name" id="" placeholder='Enter your name' />
        </div>
        <div>
            <label htmlFor="Name">Name</label>
            <input type="email" name="email" id="" placeholder='Enter your email' />
        </div>
        <div>
            <label htmlFor="Message">Message</label>
<textarea name="message" id="" cols="30" rows="5">Type your message here</textarea>
        </div>
        <button>Contact</button>
      </form>
    </div>
  )
}

export default GetTouch
