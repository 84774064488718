import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import "../assets/css/faq.css";

function Faq() {
  // Define the FAQ data as an array of objects
  const [expanded, setExpanded] = useState(0);

  const faqData = [
    {
      question: "What is Sphera World?",
      answer:
        "Sphera World is a revolutionary ecosystem that merges the world of sports, blockchain technology, and digital collectibles. It allows fans to engage with their favorite sports teams, players, and moments in a whole new way.",
    },
    {
      question: "What are Web3 and Blockchain?",
      answer:
        "Web3 refers to the third generation of the internet, characterized by decentralized, blockchain-based technologies. Blockchain is a distributed ledger technology that ensures transparency and security in digital transactions. If you're new to Web3 and blockchain, don't worry—Sphera World makes it easy for beginners to get started.",
    },
    {
      question: "How do I get started with Sphera World?",
      answer: "To get started, simply sign up on our platform, explore our digital collectibles, join our community, and start enjoying the benefits of the Sphera World ecosystem.",
    },
    {
      question: " What is Sphera Token (SPT)?",
      answer: "Sphera Token (SPT) is our native utility token that powers the Sphera World ecosystem. You can earn, trade, and use SPT for various activities within the platform.",
    },
    {
      question: " Where can I find more information and support?",
      answer: "Visit our Help Center for detailed guides and support, or contact our customer service team for assistance.",
    },
    {
      question: " What is a cryptocurrency wallet, and why do I need one?",
      answer: "A cryptocurrency wallet is a digital tool that allows you to store, send, and receive cryptocurrencies and NFTs. You need a wallet to securely manage your crypto assets and participate in blockchain transactions on sphera world.",
    },
    {
      question: "How can I connect with other Sphera World enthusiasts?",
      answer: "Join our Discord community, follow us on social media, and participate in our events to connect with other passionate Sphera World users.",
    },
  ];
  const toggleFaq = (index) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };

  return (
    <div className="faq container" id='faq'>
      <div className="faq_heading">
        <h1>Frequently Asked Questions</h1>
        <p>We are here to help</p>
      </div>
      <div className="faqs">
      {/* <div className="input-container">
        <input type="text" placeholder="General" className="input-field" />
        <input type="text" placeholder="SpheraHeads" className="input-field" />
        <input type="text" placeholder="Education" className="input-field" />
      </div> */}
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFaq(index)}>
              {item.question}
              <FontAwesomeIcon icon={expanded === index ? faMinus : faPlus} />
            </div>
            {expanded === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
