import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';


const PrivateRoutes = () => {
    const varify_user = () => {
        const storedUser = sessionStorage.getItem('userData');
        if (storedUser) { return true }
        return false
    }
    return (
        varify_user() ?
            <Outlet /> : <Navigate to='/login' />
    )
}

export default PrivateRoutes;



