import { useState, useEffect } from "react";
import { BsArrowUp } from 'react-icons/bs';
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../components/header";
import "../assets/css/banner.css";
import Banner from "../components/banner";
import Sections from "../components/section";
import "../assets/css/section.css";
import Footer from "../components/footer";
import Section2 from "../components/section2";
import ProfileTeam from "../components/team";
import '../assets/css/popup.css';
import '../assets/css/section.css';

const LandingPage = () => {
  const [showGoTop, setShowGoTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowGoTop(scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleGoTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }; 

  return (
    <div className="main" style={{ width: '100%', overflow: 'hidden' }}>
      {/* Go to Top button */}
      <button className="gotopbutton" onClick={handleGoTopClick}><BsArrowUp /></button>
      <div className="App bg-dark linear_gray">
        <div className="">
          <div className="banner_img banner_mobile">
            <Header />
            <Banner />
          </div>
        </div>
        <div className="bg-dark main_sec2">
          <Sections />
        </div>
      </div>
      <Section2 />
      <ProfileTeam />
      <Footer />

    </div>
  );
}

export default LandingPage;
