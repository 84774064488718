import React, { useState } from "react";
import icon2 from "../../assets/images/Vector.png";
import icon3 from "../../assets/images/Frame-1.png";
import info from "../../assets/images/info.png";
import middleImg from "../../assets/images/Title1.png";
import middleImg2 from "../../assets/images/ChampionsLeague2.png";
import { Col, Row } from "react-bootstrap";
// import Popup from "../../components/popup";
import Mobile_Replace from "../../components/mobile_replace";
import MobileSec from "./mobile-sec";
import img_1 from '../../assets/images/img-1.png';
import img_2 from '../../assets/images/img-2.png';
import img_3 from '../../assets/images/img-3.png';
import HeadSImg from '../../assets/images/LogoHeadStarterlogo_name.png';
import '../../assets/css/section.css'

function Ar_Section() {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  return (
    <div className="main_sec2">
      <div className="middleimages">
        <div className="banner_img3">
          <img src={middleImg} alt="" className="champion_img" />
        </div>
      </div>
      <div className="container-fluid" style={{ marginTop: '-30px' }}>
        <Row className="box_parent">
          <div className="col-md-2 ticket_parent">
            <img src={middleImg2} alt="" className="ticket_img" />
          </div>
          <div className="col-md-8">
            <div className="mainsec_3">
              <h3>سحب جوائز إطلاق سفيرا</h3>
              <p>شارك في حدث الإطلاق لتحصل على فرصة دخول في سحب الجوائز لربح تذاكر لدوري أبطال أوروبا!</p>
            </div>
          </div>
          <div className="col-md-2 ticket_parent">
            <img src={middleImg2} alt="" className="ticket2" />
          </div>
        </Row>
      </div>

      <button
        className="learn_button"
        onMouseEnter={handleMouseEnter1}
        onMouseLeave={handleMouseLeave1}
      >
        {isHovered1 ? 'Coming Soon' : 'انضم إلى حدث الإطلاق لتدخل السحب'}
      </button>

      <div className="three_shirt container">
        <div>
          <div className="img_flex">
            <img src={img_1} alt="" className="img-fluid img-1" />
            <img src={img_2} alt="" className="img-fluid img-2" />
            <img src={img_3} alt="" className="img-fluid img-3" />
          </div>
          <div className="midle_heads midle_head_ar">
            <img src={HeadSImg} alt="" />
          </div>
        </div>
        <div className="bag_sec" >
          <h3>اربح قمصان كرة القدم الموقعة</h3>
          <p>
          انضم إلى إطلاق منصتنا بدفعة البداية لتحصل على فرصة للفوز بقمصان كرة القدم الموقعة من قبل رونالدو وبنزيما ونيمار جونيور.          </p>
        </div>
      </div>
      <div className="newpara">
        <p className="newpara_p">
        هل أنت متحمس للعبة الجميلة؟
          <br /> مرحبًا بك في عالم سفيرا، انضم إلى الثورة الرقمية، تواصل مع أنديتك المفضلة، وتابع دورياتك المفضلة وامتلك مجموعة فريدة من المقتنيات، بينما تكون جزءًا من مجتمع مثير لعشاق الرياضة.
          اتصل بمستقبل ترفيه الرياضة اليوم
       </p>
      </div>
      <div className="container">
        <div className="to_flex">
          <div className="threesec"></div>
          <div className="threesec1" id="shadow1">
            <h1>رؤوس كروية</h1>
            <h3>مغامرتك الرقمية تبدأ.</h3>
            <div className="box_flex">
              <div >
                {" "}
                <img alt="" src={icon2} />
                <p className="box_flex_p">
                 .  تذاكر السحب للجوائز، والمكافآت، ومزادات السلع التذكارية، والهدايا.فتح حزم لحظات سفيرا مجانًا
                </p>
              </div>
              <div>
                {" "}
                <img alt="" src={icon3} />
                <p className="box_flex_p"> 
                
                <span>5,000</span>   <span> هوية فريدة كاملة، تفتح عالم سفيرا</span>   
               
         
                </p>
              </div>
              <div>
                {" "}
                <img alt="" src={icon3} />
                <p className="box_flex_p">
                الوصول المغلق للمجتمع على Discord، سفيرا هيدز هم دليل عضويتك.
                                </p>
              </div>
            </div>
            <div className="new_flex">
              <img src={info} alt="" />
              <a href="#">لمعرفة المزيد</a>
            </div>
          </div>
        </div>
        <div className="step_flex">
          <div className="auto_margin">
            <div className="steps_ar">
              <span>ا حصل علىSpheraHead</span>
              <span className="line_span"></span>
              <span>1.</span>
            </div>
            <p className="step_p_ar">
            فكر في السفيراهيد كجواز سفرك إلى المقتنيات الرقمية. إنها هويتك الرقمية، تذكيرك للعبة.
            </p>
          </div>
          <div className="auto_margin">
            <div className="steps_ar">
              <span>استكشف السوق</span>
              <span className="line_span"></span>
              <span>2.</span>
            </div>
            <p className="step_p_ar">
             . اغمر نفسك في سوقنا، حيث ستكتشف مجموعة متنوعة من كنوز الرياضة الرقمية. من بطاقات تداول إلى اللحظات المميزة، كل شيء هنا
            </p>
          </div>
        </div>
        <div className="step_flex">
          <div className="auto_margin">
            <div className="steps_ar">
              <span>تواصل مع الآخرين</span>
              <span className="line_span"></span>
              <span>4.</span>
            </div>
            <p className="step_p_ar">
         .  نضم إلى مجتمع تفاعلي من عشاق الرياضة بنفس شغفك. ناقش، تداول، واحتفل بالرياضة
                        </p>
          </div>
          <div className="auto_margin">
            <div className="steps_ar">
              <span>التحصيل والتداول</span>
              <span className="line_span"></span>
              <span>3.</span>
            </div>
            <p className="step_p_ar">
           . اشترِ، بيع، وتداول مقتنياتك المفضلة.إنها مثل إمتلاك جزءًا من تاريخ الرياضة في جيبك
            </p>
          </div>
        </div>
        <MobileSec />

     
      </div>
    </div>
  );
}

export default Ar_Section;
