import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import "../assets/css/header.css";
import Logo from "../assets/images/logo.png";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
function Header() {
  const [isHovered, setIsHovered] = useState(Array(9).fill(false));

  const handleMouseEnter = (index) => {
    // Update the hover state for the specific link at 'index'
    setIsHovered((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const handleMouseLeave = (index) => {
    // Update the hover state for the specific link at 'index'
    setIsHovered((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  return (
    <Navbar expand="lg" className="header">
      <Container fluid className="header_flex">
        <Navbar.Brand href="#">
          <img src={Logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href="#home"
              onMouseEnter={() => handleMouseEnter(0)}
              onMouseLeave={() => handleMouseLeave(0)}
            >
              {isHovered[0] ? "Coming Soon" : "SpheraHeads"}
            </Nav.Link>
            <Nav.Link
              href="#link"
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={() => handleMouseLeave(1)}
            >
              {isHovered[1] ? "Coming Soon" : "Sphera Token"}
            </Nav.Link>
            <Nav.Link href="https://app.headstarter.org/" target="_blank">INO</Nav.Link>
            <Nav.Link href="#faq">FAQ</Nav.Link>
            <Nav.Link href="#patners">Blog</Nav.Link>
            <Nav.Link
              href="/about"
          
            >
             About Us
            </Nav.Link> 
            <Nav.Link href="https://linktr.ee/spheraworld" target="_blank">Community</Nav.Link>
            <Nav.Link href="https://linktr.ee/spheradoc" target="_blank">Docs</Nav.Link>
            <Nav.Link href="https://t.me/SpheraWorldPortal" target="_blank">Contact</Nav.Link>
            <Link to="/AuthOptions" className="open_app" style={{ color:"#fff", textDecoration:'none' }}>Open App</Link>
            {/* <Nav.Link href="/ar">العربية</Nav.Link> */}

            <DropdownButton id="dropdown-basic-button" title="EN" className="drop_down_trans">
      {/* <Dropdown.Item href="/ar">العربية</Dropdown.Item> */}
         <Dropdown.Item>      <Link to="/ar">العربية</Link> </Dropdown.Item> 
     
    </DropdownButton>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
