import React from "react";
import "../../assets/css/footer.css";
import Logo from "../../assets/images/logo.png";
import "../ar.css/footer.css"
function Footer() {
  return (
    <div className="footer">
      <div className="footer_flex">
      <div>
          <div className="footer_links">
          <a href="#">جديد</a>
          <a href="#">معلومات عنا</a>
            <a href="#">سياسة الخصوصية</a>
         
           
          </div>
          <div className="footer_links">
          <a href="#">الأسئلة الشائعة</a>
          <a href="#">اتصل بنا</a>
            <a href="#">شروط الخدمة</a>
    
            
          </div>
        </div>
        <div>
          <img src={Logo} alt="" className="footer_ar_img" /> <br />
          <p>
          توفير النظام البيئي للمقتنيات الرقمية
تجارب جديدة لعشاق الرياضة.
          </p>
        </div>
      
      </div>

      <div className="footer_bottomflelx">
      <div>
          <ul>
            
           
           
            <a target="_blank" href="https://www.facebook.com/spheraworld">
            <li >
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2301_3309)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 21C0 9.40205 9.40205 0 21 0C32.5979 0 42 9.40205 42 21C42 32.5979 32.5979 42 21 42C9.40205 42 0 32.5979 0 21ZM23.1889 33.3476V21.9224H26.3427L26.7606 17.9853H23.1889L23.1942 16.0147C23.1942 14.9878 23.2918 14.4376 24.7667 14.4376H26.7383V10.5H23.584C19.7952 10.5 18.4617 12.4099 18.4617 15.6219V17.9857H16.1V21.9229H18.4617V33.3476H23.1889Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2301_3309">
                    <rect width="42" height="42" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </li>
            </a>
            <a target="_blank" href="https://twitter.com/spheraworld">
            <li>
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2301_3307)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 21C0 9.40205 9.40205 0 21 0C32.5979 0 42 9.40205 42 21C42 32.5979 32.5979 42 21 42C9.40205 42 0 32.5979 0 21ZM20.371 17.0691L20.4151 17.7957L19.6807 17.7068C17.0073 17.3657 14.6717 16.2089 12.6886 14.2663L11.7192 13.3024L11.4695 14.0142C10.9406 15.6009 11.2785 17.2767 12.3802 18.4037C12.9678 19.0265 12.8355 19.1155 11.822 18.7448C11.4695 18.6261 11.161 18.5372 11.1317 18.5817C11.0288 18.6854 11.3813 20.035 11.6604 20.5688C12.0424 21.3103 12.8208 22.037 13.6728 22.467L14.3926 22.8081L13.5406 22.8229C12.718 22.8229 12.6886 22.8378 12.7768 23.1492C13.0706 24.1131 14.231 25.1363 15.5236 25.5812L16.4344 25.8927L15.6412 26.3672C14.466 27.0493 13.0853 27.4349 11.7045 27.4646C11.0435 27.4794 10.5 27.5387 10.5 27.5832C10.5 27.7315 12.2921 28.5619 13.335 28.8882C16.4637 29.8521 20.1801 29.4369 22.971 27.7908C24.954 26.6193 26.937 24.291 27.8624 22.037C28.3619 20.8358 28.8614 18.641 28.8614 17.5881C28.8614 16.906 28.9054 16.817 29.728 16.0013C30.2127 15.5268 30.6681 15.0077 30.7563 14.8594C30.9031 14.5777 30.8885 14.5777 30.1393 14.8298C28.8908 15.2747 28.7144 15.2154 29.3314 14.548C29.7867 14.0735 30.3302 13.2134 30.3302 12.9613C30.3302 12.9167 30.1099 12.9909 29.8602 13.1244C29.5958 13.2727 29.0083 13.4951 28.5675 13.6286L27.7743 13.8807L27.0546 13.3914C26.658 13.1244 26.0998 12.8278 25.806 12.7389C25.0569 12.5312 23.9111 12.5609 23.2355 12.7982C21.3993 13.4655 20.2388 15.1857 20.371 17.0691Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2301_3307">
                    <rect width="42" height="42" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </li>
            </a>
              <a target="_blank" href="https://www.instagram.com/sphera.world/">
            <li>
            
           
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2301_3311)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 21C0 9.40205 9.40205 0 21 0C32.5979 0 42 9.40205 42 21C42 32.5979 32.5979 42 21 42C9.40205 42 0 32.5979 0 21ZM21.001 9.8C17.9593 9.8 17.5775 9.8133 16.3829 9.86764C15.1905 9.92224 14.3767 10.1111 13.6645 10.388C12.9279 10.674 12.303 11.0568 11.6805 11.6795C11.0575 12.3021 10.6747 12.9269 10.3877 13.6633C10.1101 14.3757 9.9211 15.1898 9.86746 16.3817C9.814 17.5764 9.8 17.9583 9.8 21.0001C9.8 24.0418 9.81356 24.4225 9.86764 25.6171C9.9225 26.8095 10.1112 27.6233 10.388 28.3355C10.6743 29.0721 11.0569 29.697 11.6798 30.3195C12.3021 30.9425 12.9269 31.3261 13.6631 31.6123C14.3757 31.8892 15.1898 32.0779 16.3819 32.1325C17.5767 32.187 17.9581 32.2003 20.9996 32.2003C24.0417 32.2003 24.4222 32.187 25.6169 32.1325C26.8092 32.0779 27.624 31.8892 28.3367 31.6123C29.0731 31.3261 29.697 30.9425 30.3193 30.3195C30.9423 29.697 31.325 29.0721 31.612 28.3357C31.8874 27.6233 32.0764 26.8092 32.1324 25.6174C32.186 24.4227 32.2 24.0418 32.2 21.0001C32.2 17.9583 32.186 17.5767 32.1324 16.3819C32.0764 15.1896 31.8874 14.3757 31.612 13.6636C31.325 12.9269 30.9423 12.3021 30.3193 11.6795C29.6963 11.0565 29.0733 10.6739 28.336 10.388C27.6219 10.1111 26.8076 9.92224 25.6153 9.86764C24.4205 9.8133 24.0403 9.8 20.9976 9.8H21.001Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.9953 11.8182C20.2935 11.8178 20.6263 11.8182 21.0001 11.8182C23.9905 11.8182 24.3449 11.829 25.5258 11.8826C26.6178 11.9326 27.2106 12.115 27.6054 12.2683C28.128 12.4713 28.5007 12.714 28.8924 13.106C29.2844 13.498 29.5271 13.8713 29.7306 14.3941C29.8839 14.7883 30.0666 15.3811 30.1163 16.4731C30.1699 17.6537 30.1816 18.0084 30.1816 20.9974C30.1816 23.9864 30.1699 24.3411 30.1163 25.5218C30.0663 26.6138 29.8839 27.2065 29.7306 27.6008C29.5276 28.1235 29.2844 28.4956 28.8924 28.8875C28.5004 29.2795 28.1283 29.5221 27.6054 29.7251C27.211 29.8791 26.6178 30.0611 25.5258 30.1111C24.3452 30.1647 23.9905 30.1763 21.0001 30.1763C18.0094 30.1763 17.655 30.1647 16.4743 30.1111C15.3823 30.0607 14.7896 29.8781 14.3946 29.7248C13.8719 29.5218 13.4985 29.2792 13.1065 28.8872C12.7145 28.4952 12.4719 28.1228 12.2685 27.5999C12.1152 27.2055 11.9325 26.6129 11.8828 25.5209C11.829 24.3402 11.8184 23.9855 11.8184 20.9946C11.8184 18.0037 11.829 17.6509 11.8828 16.4703C11.9326 15.3783 12.1152 14.7855 12.2685 14.3907C12.4715 13.8681 12.7145 13.4947 13.1065 13.1027C13.4985 12.7107 13.8719 12.4681 14.3946 12.2646C14.7894 12.1106 15.3823 11.9286 16.4743 11.8784C17.5075 11.8318 17.9079 11.8178 19.9953 11.8154V11.8182ZM26.9786 13.6779C26.2366 13.6779 25.6346 14.2793 25.6346 15.0214C25.6346 15.7634 26.2366 16.3654 26.9786 16.3654C27.7206 16.3654 28.3226 15.7634 28.3226 15.0214C28.3226 14.2794 27.7206 13.6779 26.9786 13.6779ZM21.0001 15.2482C17.8237 15.2482 15.2484 17.8236 15.2484 21C15.2484 24.1764 17.8237 26.7505 21.0001 26.7505C24.1765 26.7505 26.7508 24.1764 26.7508 21C26.7508 17.8236 24.1765 15.2482 21.0001 15.2482Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.0011 17.2666C23.0628 17.2666 24.7344 18.938 24.7344 21C24.7344 23.0617 23.0628 24.7333 21.0011 24.7333C18.939 24.7333 17.2677 23.0617 17.2677 21C17.2677 18.938 18.939 17.2666 21.0011 17.2666Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2301_3311">
                    <rect width="42" height="42" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </li>
            </a>
              <a target="_blank" href="https://t.me/SpheraWorldPortal">
            <li>
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2301_3315)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M42 21C42 32.5979 32.5979 42 21 42C9.40205 42 0 32.5979 0 21C0 9.40205 9.40205 0 21 0C32.5979 0 42 9.40205 42 21ZM14.0626 19.0763C11.9589 19.9948 9.79746 20.9386 7.82097 22.0273C6.78892 22.7829 8.16058 23.3174 9.44746 23.8188C9.65204 23.8985 9.85443 23.9774 10.0447 24.0561C10.2031 24.1049 10.3641 24.1561 10.5273 24.2081C11.9585 24.6641 13.5544 25.1725 14.9439 24.4077C17.2264 23.0965 19.3804 21.5807 21.5328 20.0659C22.2379 19.5696 22.9429 19.0735 23.6522 18.5847C23.6855 18.5635 23.7229 18.5392 23.764 18.5126C24.3681 18.1209 25.7271 17.24 25.2244 18.4538C24.0358 19.7537 22.7626 20.9044 21.4826 22.0614C20.6198 22.8411 19.7539 23.6237 18.9087 24.4567C18.1725 25.0548 17.408 26.2577 18.2324 27.0953C20.131 28.4245 22.0594 29.7214 23.9865 31.0176C24.6137 31.4394 25.2408 31.8611 25.8666 32.2839C26.9272 33.1307 28.5849 32.4457 28.8181 31.1224C28.9218 30.5137 29.0259 29.905 29.1301 29.2963C29.7055 25.9322 30.281 22.5669 30.7895 19.1918C30.8586 18.6623 30.9369 18.133 31.0153 17.6033C31.2053 16.3196 31.3955 15.0343 31.4549 13.7437C31.3018 12.4557 29.7401 12.7389 28.8711 13.0286C24.404 14.7283 19.9816 16.5541 15.5767 18.4121C15.0777 18.6331 14.5718 18.854 14.0626 19.0763Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2301_3315">
                    <rect width="42" height="42" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </li>
            </a>
              <a target="_blank" href="https://discord.com/invite/CwM2H5GUcR">
            <li>
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2301_3317)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 21C0 9.40205 9.40205 0 21 0C32.5979 0 42 9.40205 42 21C42 32.5979 32.5979 42 21 42C9.40205 42 0 32.5979 0 21ZM29.658 9.1H13.042C11.6408 9.1 10.5 10.227 10.5 11.6235V28.1855C10.5 29.582 11.6408 30.709 13.042 30.709H27.1036L26.4464 28.4428L28.0336 29.9005L29.534 31.2725L32.2 33.6V11.6235C32.2 10.227 31.0592 9.1 29.658 9.1Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M24.8724 25.0987C24.8724 25.0987 24.426 24.5719 24.054 24.1064C25.6784 23.6532 26.2984 22.6487 26.2984 22.6487C25.79 22.9794 25.3064 23.2122 24.8724 23.3714C24.2524 23.6287 23.6572 23.8002 23.0744 23.8982C21.884 24.1187 20.7928 24.0574 19.8628 23.8859C19.156 23.7512 18.5484 23.5552 18.04 23.3592C17.7548 23.2489 17.4448 23.1142 17.1348 22.9427C17.0976 22.9182 17.0604 22.9059 17.0232 22.8814C16.9984 22.8692 16.986 22.8569 16.9736 22.8447C16.7504 22.7222 16.6264 22.6364 16.6264 22.6364C16.6264 22.6364 17.2216 23.6164 18.7964 24.0819C18.4244 24.5474 17.9656 25.0987 17.9656 25.0987C15.2252 25.0129 14.1836 23.2367 14.1836 23.2367C14.1836 19.2922 15.9692 16.0949 15.9692 16.0949C17.7548 14.7719 19.4536 14.8087 19.4536 14.8087L19.5776 14.9557C17.3456 15.5927 16.3164 16.5604 16.3164 16.5604C16.3164 16.5604 16.5892 16.4134 17.048 16.2052C18.3748 15.6294 19.4288 15.4702 19.8628 15.4334C19.9372 15.4212 19.9992 15.4089 20.0736 15.4089C20.83 15.3109 21.6856 15.2864 22.5784 15.3844C23.7564 15.5192 25.0212 15.8622 26.3108 16.5604C26.3108 16.5604 25.3312 15.6417 23.2232 15.0047L23.3968 14.8087C23.3968 14.8087 25.0956 14.7719 26.8812 16.0949C26.8812 16.0949 28.6668 19.2922 28.6668 23.2367C28.6668 23.2367 27.6128 25.0129 24.8724 25.0987ZM19.4556 19.6002C18.7544 19.6002 18.2008 20.2308 18.2008 21.0002C18.2008 21.7696 18.7667 22.4002 19.4556 22.4002C20.1569 22.4002 20.7105 21.7696 20.7105 21.0002C20.7227 20.2308 20.1569 19.6002 19.4556 19.6002ZM23.946 19.6002C23.2447 19.6002 22.6911 20.2308 22.6911 21.0002C22.6911 21.7696 23.2571 22.4002 23.946 22.4002C24.6472 22.4002 25.2008 21.7696 25.2008 21.0002C25.2008 20.2308 24.6472 19.6002 23.946 19.6002Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2301_3317">
                    <rect width="42" height="42" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </li>
            </a>

            <li>يتبع</li>
          </ul>
        </div>
        <div>
          <h5>© Sphera 2023. كل الحقوق محفوظة</h5>
        </div>
      
      </div>
    </div>
  );
}

export default Footer;
