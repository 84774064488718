import React from "react";
import "../../assets/css/gettouch.css";
import arrow from "../../assets/images/arrow-right.svg";


function GetTouch() {
  return (
    <div className="gettouch">
      <h1>لحظات سفيرا</h1>
      <p>نَودُّ أن نَتلقَّى رسالتك الرجاء ملء هذا النموذج.</p>
      <button>اتصل بنا <img src={arrow} alt="" /></button>
    </div>
  );
}

export default GetTouch;
