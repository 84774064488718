import React, { useState, useLayoutEffect, Fragment, useEffect } from "react";
import Toast_Card, { showToast } from "../../components/common/Toast_Card.jsx";
import Logo from "../../assets/images/logo.png";
import { useCheckWalletIDQuery } from "../../Redux/Auth/Auth";
import { Link } from "react-router-dom";
import circleLogo from "../../assets/images/circle.png";
import Playerimg from "../../assets/images/bottom-player.png";
import Blade from "../../assets/images/blad.png";
import "../../assets/css/conect-web3.css";
import "../../views/ViewStyles.css";
import "./index95.js";
import { useNavigate } from "react-router-dom";

const ConnectWeb3 = () => {
  const navigate = useNavigate();
  const [onboard, setOnboard] = useState(); 
  const [showLoading, setShowLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false); // Track button click
  const [AuthenticUser, setAuthenticUser] = useState({ User_Email: '', User_ID: '' });

  useLayoutEffect(() => {
    const isPageRefreshed = sessionStorage.getItem("isPageRefreshed");
    if (!isPageRefreshed) {
      sessionStorage.setItem("isPageRefreshed", "true");
      window.location.reload();
    }

    const User_ID = sessionStorage.getItem('Auth_User_id');
    const User_Email = sessionStorage.getItem('Auth_User_Email');

    setAuthenticUser({
      User_Email: User_Email || '',
      User_ID: User_ID || ''
    });

    setOnboard({
      width: "100%",
      height: "100vh",
      overflow: "hidden",
      color: "#ffffff",
      backgroundImage: ` url(${require("../../assets/images/OnBard/PNG/line1.png")})`,
      backgroundPosition: "250px 63px, 34% -12px",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundColor: "black",
    });
  }, []);

  const email = AuthenticUser?.User_Email;
  const { data,status } = useCheckWalletIDQuery(email, { pollingInterval: 3000 });

  const handleBladeNFTAirdropClick = async () => {
    setButtonClicked(true);
  };

  useEffect(() => {
    if (buttonClicked) {
      if (data && data?.type === 101) {
        const email = data.data?.email
        sessionStorage.setItem("userData", email);
        navigate('/redirect_blade');
      } else {
        setShowLoading(true);
        const reloadTimeout = setTimeout(() => {
          window.location.reload();
        }, 60000); 
        return () => clearTimeout(reloadTimeout);
      }
    }
  }, [buttonClicked, data, status]);

  return (
    <Fragment>
      <Toast_Card />
      <div style={onboard} className="onboard">
        <div className="onboard_header">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
          <Link className="arabic_link">العربية</Link>
        </div>
        <div className="onboardcontainer">
          <div className="left_column left_side">
            <h1>Connecting to .<span className="colortext"> Web3</span></h1>
            <p className="desc">Choose how you would like to connect.</p>
          </div>
          <div className="right_column" style={{ overflow: "visible" }}>
            <div className="box_detail">
              <img src={circleLogo} alt="" className="circle_img" />
              <div className="center_box_web3">
                <div>
                  <h2>Download Blade</h2>
                  <p>
                    Blade wallet is a Web3 asset management tool to help you
                    interact with the Sphera World platform.
                  </p>
                  <img src={Blade} alt="" />
                  <div style={{ height: "48px", overflow: 'hidden' }}>
                    {showLoading ? (
                      <div>Connecting...</div>
                    ) : (
                      <div
                        id="bladeNFTAirdrop"
                        data-use-testnet="1"
                        data-dapp-code="sphera95201"
                        data-dapp-nonce="34236928"
                        data-bl-custom-field="42"
                        data-bl-user={AuthenticUser?.User_Email}
                        data-bl-user-id={AuthenticUser?.User_ID}
                        onClick={handleBladeNFTAirdropClick}
                      ></div>
                    )}
                  </div>
                  <Link className="find_more" to="/">Find out more</Link>
                </div>
              </div>
              <img src={Playerimg} alt="" className="player_img" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConnectWeb3;
