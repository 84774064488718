import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../assets/css/onboard.css";
import ImgSlid from '../assets/images/slider1.png';

const NextArrow = ({ onClick }) => (
  <button className="custom-next-arrow" onClick={onClick}>
    Next
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button className="custom-prev-arrow" onClick={onClick}>
    Previous
  </button>
);

const SlickSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="slick-slider-container">
      <Slider {...settings}>
        <div className='slid_img_div'>
          <img src={ImgSlid} alt="Slide 1" className='img_slid' />
          <div className='overlay_img'> 
          <h3>Connecting Football to the
            <span>  world of Web3</span>
           </h3>
          <p>The Future of fan engagement and sports incentives is built on Web3. Connect to your favourite teams in a new way through Sphera World.</p>
          </div>   
               </div>
        <div>
        <img src={ImgSlid} alt="Slide 1" className='img_slid' />
           
        </div>
        <div>
          <img src="https://placekitten.com/800/402" alt="Slide 3" />
        
      
        </div>
        {/* Add more slides as needed */}
      </Slider>
    </div>
  );
};

export default SlickSlider;
