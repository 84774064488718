import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const Auth_APi = createApi({
    reducerPath: "Auth_APi",
      baseQuery: fetchBaseQuery({ baseUrl: "https://spheraworldapi-n2qq.onrender.com/api/" }),
   // baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:8000/api/" }),
    endpoints: (builder) => ({
        // Group API's
        register: builder.mutation({
            query: (UserData) => ({
                url: "register",
                method: "POST",
                body: UserData
            }),
        }),

        login: builder.mutation({
            query: (UserData) => ({
                url: "login",
                method: "POST",
                body: UserData
            }),
        }),

        otp: builder.mutation({
            query: (OtpData) => ({
                url: "otp",
                method: "POST",
                body: OtpData
            }),
        }),

        WalletID: builder.mutation({
            query: (UserData) => ({
                url: "/SaveWalletID",
                method: "POST",
                body: UserData
            }),
        }),

        UserAccountInformation: builder.query({
            query: (userId) => ({
                url: `/userInformation/${userId}`,
                method: "GET",
            }),
        }),

        ForgetPasswordEmail: builder.mutation({
            query: (UserData) => ({
                url: "/ForgetPasswordEmail",
                method: "POST",
                body: UserData
            }),
        }),

        ForgetPasswordOTP: builder.mutation({
            query: (UserData) => ({
                url: "/forgetpasswordotp",
                method: "POST",
                body: UserData
            }),
        }),

        Resetpassword: builder.mutation({
            query: (UserData) => ({
                url: "/resetpassword",
                method: "POST",
                body: UserData
            }),
        }),

        checkWalletID: builder.query({
            query: (email) => ({
                url: `/checkWalletID/${email}`,
                method: "GET",
            }),
        }),
    })
});

export const {
    useRegisterMutation,
    useLoginMutation,
    useOtpMutation,
    useWalletIDMutation,
    useUserAccountInformationQuery,
    useForgetPasswordEmailMutation,
    useForgetPasswordOTPMutation,
    useResetpasswordMutation,
    useCheckWalletIDQuery,
} = Auth_APi;
