import React from "react";
import "../../assets/css/subscription.css";
// import StepPopup from "./step";
import "../ar.css/subscription.css"
function Subscription() {
  return (
    <div className="margin_auto">
    <div className="subcription_ar container">
      <h1>اشترك في نشرتنا</h1>
      <p>انضم إلى مجتمعنا للتعرف على صفقات حصرية وآخر الأخبار.</p>
      <form action="https://spheraworld.us8.list-manage.com/subscribe/post?u=00477a26a8ab091e787afccf8&amp;id=f11f811364&amp;f_id=005f70e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
   
      <div className="flex_input">
        <input name="EMAIL" class="required email" id="mce-EMAIL"  type="text" placeholder="أدخل عنوان بريدك الإلكتروني" className="inp_sub" />
        <button type="submit" name="subscribe" id="mc-embedded-subscribe">اشتراك</button>

      </div>
      {/* <StepPopup />   */}
   </form>   
      </div>
      
    </div>
  );
}

export default Subscription;
