import React, { useState, useLayoutEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import circleLogo from "../../assets/images/circle.png";
import Playerimg from "../../assets/images/bottom-player.png";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { useResetpasswordMutation } from "../../Redux/Auth/Auth";
import "../../assets/css/conect-web3.css";
import "../../views/ViewStyles.css";
import Toast_Card, { showToast } from "../../components/common/Toast_Card.jsx"
import { BsArrowRight } from "react-icons/bs";
import RingLoader from "react-spinners/RingLoader";
import Btn from '../../components/shared/Btn.jsx'



const ExistingUserChangePassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const navigate = useNavigate();
    const handleSignUp = () => navigate("/onBoard")

    const [onboard, setonboard] = useState();
    useLayoutEffect(() => {
        setonboard({
            width: "100%",
            height: "100vh",
            overflow: "hidden",
            color: "#ffffff",
            backgroundImage: ` url(${require("../../assets/images/OnBard/PNG/line5.png")})`,
            backgroundPosition: "250px 63px, 34% -12px",
            backgroundRepeat: "no-repeat, no-repeat",
            backgroundColor: "black",
        });
    }, []);


    // Query 
    const [UserLogin] = useResetpasswordMutation();
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const emailFromSession = sessionStorage.getItem("resetEmail");

        const UserData = {
            email: emailFromSession,
            password: formData.password
        };

        try {
            const res = await UserLogin(UserData);
            if (res.error) {
                setIsLoading(false);
                showToast('Wrong Credentials.', 'error');
            } else {
                sessionStorage.setItem("userData", res.data?.user?.email);
                navigate('/login')
            }
        } catch (error) {
            console.error("An error occurred during login:", error);
            showToast('An error occurred. Please try again later.', 'error');
        }
    };



    return (
        <Fragment>
            <Toast_Card />
            <div style={onboard} className="onboard">
                <div className="onboard_header">
                    <Link to="/">
                        <img src={Logo} alt="" />
                    </Link>
                    <Link className="arabic_link">العربية</Link>
                </div>
                <div className="onboardcontainer">
                    <div className="left_column left_side">
                    </div>
                    <div className="right_column right_col_signup" style={{ overflow: "visible" }}  >
                        <div className=" box_detail">
                            <img
                                src={circleLogo}
                                alt=""
                                className="circle_img circle_img_signup"
                            />
                            <div className="signup_form">
                                <h3>Reset Password </h3>
                                <form onSubmit={handleLogin}>
                                    <div>
                                        <label htmlFor="Name">New Password</label>
                                        <input type="password" name="email" id="email" value={formData.email} onChange={handleInputChange} required />
                                    </div>
                                    <div className="para_link">
                                        <label htmlFor="Name">Confirm Password</label>
                                        <input type="password" name="password" id="password" value={formData.password} onChange={handleInputChange} required />
                                    </div>
                                    <p className="para_link">By clicking the button, you agree to the{" "}<Link>Terms of Service</Link> .</p>
                                    <Btn text="Change Password" disabled={isLoading} icon={
                                        !isLoading ? <BsArrowRight style={{ fontSize: '16px', color: '#FFF' }} /> : <RingLoader color={"#FFFFFF"} loading={true} size={24} />}
                                        type="submit"
                                        style={{ backgroundColor: '#007BFF', color: '#fff', fontSize: '14px', height: '40px', margin: 'auto', display: 'block' }} />
                                </form>
                            </div>
                            <img
                                src={Playerimg}
                                alt=""
                                className="player_img signup_screen_img"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ExistingUserChangePassword;
