import React, { useState, useLayoutEffect, Fragment } from 'react'
import Logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom';
import '../../views/ViewStyles.css';
import circleLogo from '../../assets/images/circle.png';
import Playerimg from '../../assets/images/bottom-player.png';
import '../../assets/css/conect-web3.css';
import { useNavigate } from 'react-router-dom';



const AccouctVerified = () => {

  const navigate = useNavigate();
  const handleVerified = () => { navigate('/connect') }

  const [onboard, setonboard] = useState();
  useLayoutEffect(() => {
    setonboard({
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
      color: '#ffffff',
      backgroundImage: ` url(${require('../../assets/images/OnBard/PNG/line5.png')})`,
      backgroundPosition: '250px 63px, 34% -12px',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundColor: 'black',
    })
  }, [])


  return (
    <Fragment>
      <div style={onboard} className='onboard'>
        <div className='onboard_header'>
          <Link to='/'>
            <img src={Logo} alt="" />
          </Link>
          <Link className='arabic_link'>العربية</Link>
        </div>
        <div className='onboardcontainer'>
          <div className='left_column left_side'>
            <h1>Congratulations! <br />
              <span className='colortext'>
                Account verified.</span></h1>
            <p className='desc'>A code has been sent to your email address.</p>
          </div>
          <div className='right_column right_col_signup' style={{ overflow: 'visible' }}>

            <div className=' box_detail'>
              <img src={circleLogo} alt="" className='circle_img circle_img_signup' />
              <div className='signup_form verify_form accout_verfy'>
                <h3>Account Verified</h3>
                <span className='verify_txt'>Connect your account to Web3 and setup your wallet next.</span>
                <br />
                <br />
                <button className='resend_btn' onClick={handleVerified}>Next</button> <br />
                <p className='para_link'> <Link>The next step to creating your Web3 profile.</Link> </p>
              </div>
              <img src={Playerimg} alt="" className='player_img signup_screen_img' />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AccouctVerified  
