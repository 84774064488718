import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import circleLogo from '../../assets/images/circle.png';
import Playerimg from '../../assets/images/bottom-player.png';
import { useWalletIDMutation } from '../../Redux/Auth/Auth';
import '../../assets/css/conect-web3.css';
import '../../views/ViewStyles.css';

const OLDConnectWeb3Slid3 = () => {
    const [onboard, setOnboard] = useState();
    const { param } = useParams();
    const [AuthenticUser, SetUserData] = useState(null);
    const [id, SetID] = useState(null);
    const [AddWalletID] = useWalletIDMutation();

    useLayoutEffect(() => {
        setOnboard({
            width: '100%',
            height: '100vh',
            overflow: 'hidden',
            color: '#ffffff',
            backgroundImage: `url(${require('../../assets/images/OnBard/PNG/line1.png')})`,
            backgroundPosition: '250px 63px, 34% -12px',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundColor: 'black',
        });
    }, []);

    useEffect(() => {
        const currentURL = window.location.href;
        console.log('Current URL:', currentURL);
        const currentURL1 = decodeURIComponent(currentURL);

        if (currentURL1) {
            const answer_array = currentURL1.split('?');
            console.log(answer_array);

            if (answer_array.length > 1) {
                const answer_array1 = answer_array[1].split('&');

                const finalemail = answer_array1[0].split('=');
                const finalWID = answer_array1[4].split('=');
                // console.log(finalemail[1] + 'rr' + finalWID[1]);
                const User_Email = finalWID[1];
                const WID = finalemail[1];
                sessionStorage.setItem("userData", User_Email);

                const UserData = {
                    email: User_Email,
                    walletID: WID,
                };

                try {
                    const res = AddWalletID(UserData);

                    if (res.error) {
                        // Handle error case
                    } else {
                        // Handle success case, e.g., navigate to "/profile"
                    }
                } catch (error) {
                    console.error('An error occurred during login:', error);
                }
            } else {
                console.error('Invalid URL structure: no "?" found.');
            }
        } else {
            console.error('Current URL is undefined.');
        }
    }, []);

    const handleOpenMoonPay = () => {
        const moonPayURL =
            'https://buy-sandbox.moonpay.com/?apiKey=pk_test_oxQY1qdAGKlItZrVIRQ9qpNwpfAPHjQ&theme=dark&baseCurrencyCode=usd&baseCurrencyAmount=100&defaultCurrencyCode=eth&colorCode=%237d01ff&mpSdk=%7B%22version%22%3A%221.6.3%22%2C%22environment%22%3A%22sandbox%22%2C%22flow%22%3A%22buy%22%2C%22variant%22%3A%22newTab%22%2C%22platform%22%3A%22web%22%7D';

        // Open MoonPay URL in a new tab
        window.open(moonPayURL, '_blank');
    };

    useLayoutEffect(() => {
        const isPageRefreshed = sessionStorage.getItem("isbladePageRefreshed");
        if (!isPageRefreshed) {
            sessionStorage.setItem("isbladePageRefreshed", "true");
            window.location.reload();
        }
    })


    return (
        <Fragment>
            <div style={onboard} className="onboard">
                <div className="onboard_header">
                    <Link to="/">
                        <img src={Logo} alt="" />
                    </Link>
                    <Link className="arabic_link">العربية</Link>
                </div>
                <div className="onboardcontainer">
                    <div className="left_column left_side">
                        <h1>
                            Connecting to .<span className="colortext"> Web3</span>
                        </h1>
                        <p className="desc">Choose how you would like to connect.</p>
                    </div>
                    <div className="right_column" style={{ overflow: 'visible' }}>
                        <div className=" box_detail">
                            <img src={circleLogo} alt="" className="circle_img" />
                            <div className="center_box_web3">
                                <div>
                                    <h2>Wallet Connected Successfully.</h2>
                                    <p>
                                        Please close this browser and go back to spheraworld website to visit your profile and start your journey into sphere world
                                    </p>
                                    <div className="img_flex">
                                        {/* <Link to={`/profile`} className="btn_create">Visit Profile</Link>
                    <button
                      className="btn_create"
                      style={{ marginLeft: '10px' }}
                      onClick={handleOpenMoonPay}
                    >
                      Topup Wallet
                    </button> */}

                                    </div>
                                </div>
                            </div>
                            <img src={Playerimg} alt="" className="player_img" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default OLDConnectWeb3Slid3;
